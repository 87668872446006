.exclusions {
  column-gap: 2rem;
  display: grid;
  grid-template-areas:
    'alert'
    'calendar'
    'schedules'
    'excluded';
  grid-template-columns: 100%;
  margin-bottom: 5rem;
  row-gap: 2rem;

  &__alert {
    grid-area: alert;
  }

  &__calendar {
    display: flex;
    grid-area: calendar;
    justify-content: center;
  }

  &__schedules {
    grid-area: schedules;
    min-width: 20rem;
  }

  &__schedules-title {
    border-bottom: 0.1rem solid rgb(var(--gray-step-700));
    color: var(--ion-color-primary);
    font-weight: var(--font-semibold);
    margin-bottom: 1rem;
    padding: 1rem 0;
    text-align: center;
  }

  &__excluded {
    display: flex;
    flex-direction: column;
    grid-area: excluded;
    min-width: 30rem;
    row-gap: 1rem;
  }

  &__excluded-title {
    align-items: center;
    border-bottom: 0.1rem solid rgb(var(--gray-step-700));
    color: rgb(var(--red));
    column-gap: 1rem;
    display: flex;
    font-weight: var(--font-semibold);
    justify-content: center;
    margin-bottom: 1rem;
    padding: 1rem 0;
    text-align: center;
  }

  &__excluded-item {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    @include tablet-landscape {
      justify-content: flex-start;
    }
  }

  &__excluded-reserves {
    cursor: pointer;

    &:hover {
      --background: var(--ion-color-primary-tint, #4c8dff);
    }
  }

  &__delete {
    color: rgb(var(--red));
    cursor: pointer;
  }

  @include tablet-landscape {
    grid-template-areas:
      'alert alert alert'
      'calendar schedules excluded';
    grid-template-columns: repeat(3, min-content);
  }
}
