.subheader {
  align-items: center;
  display: flex;
  height: 10rem;
  justify-content: center;
  position: sticky;
  top: 6rem;
  z-index: 1000;

  &__container {
    display: flex;
    max-width: 144rem;
    padding: 0 2rem;
    row-gap: 4.5rem;
    width: 100%;
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__subtitle {
    color: rgb(var(--gray-step-1050));
  }

  &--dark-bg {
    background-color: rgb(var(--gray-step-400));
  }

  @include mobile-landscape {
    top: 9rem;
  }
}
