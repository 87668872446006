.list-add {
  margin-bottom: 2rem;

  &--border {
    border-bottom: 0.1rem solid rgb(var(--gray-step-600));
  }
}

.list-editable {
  margin-bottom: 2rem;

  &__item {
    border-bottom: 0.1rem solid rgb(var(--gray-step-600));
    padding: 0 0 1.5rem;

    &:not(:first-child) {
      padding: 1.5rem 0;
    }
  }

  &__info {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-size: var(--font-size-lg);
    font-weight: var(--font-regular);
  }

  &__edit-button {
    color: var(--ion-color-primary);
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);

    &--active {
      color: rgb(var(--gray-step-800));
    }
  }

  &__edit-form {
    padding: 1.5rem 0 2rem;
  }
}
