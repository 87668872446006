.category-list {
  position: relative;

  &--loaded::after {
    background: linear-gradient(
      to left,
      rgb(var(--gray-step-320)),
      var(--transparent)
    );
    content: '';
    height: 90%;
    position: absolute;
    right: 0;
    top: 0.7rem;
    width: 50px;
    z-index: var(--over);
  }

  &__nodata {
    align-items: center;
    display: flex;
    padding: 0 3rem;
  }

  &__card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 6.8rem;
    margin-right: 2.5rem;
    place-content: center;
    place-items: center;
    position: relative;
    row-gap: 0.5rem;

    @include mobile-landscape {
      height: 10rem;
      margin-right: 5rem;
      row-gap: 1rem;
    }
  }

  &__slide {
    align-items: center;
    display: flex;
    height: 6.8rem;

    @include mobile-landscape {
      height: 10rem;
    }
  }

  &__swiper {
    overflow: visible;
  }

  &__icon {
    color: rgb(var(--gray-step-900));
    fill: rgb(var(--gray-step-900));
    height: 2.9rem;
    width: 2.9rem;

    &--secondary {
      color: rgb(var(--gray-step-925));
      fill: rgb(var(--gray-step-925));
    }

    @include mobile-landscape {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  &__title {
    color: rgb(var(--gray-step-900));
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-regular);
    text-align: center;
    white-space: nowrap;

    &--secondary {
      color: rgb(var(--gray-step-925));
    }

    @include mobile-landscape {
      font-size: var(--font-size-xs);
    }
  }

  &--active {
    & .category-list__icon {
      fill: rgb(var(--gray-step-1050));
    }

    & .category-list__title {
      color: rgb(var(--gray-step-1050));
    }
  }

  &--border::after {
    background: rgb(var(--gray-step-1050));
    bottom: 0;
    content: '';
    height: 0.2rem;
    position: absolute;
    width: 100%;

    @include mobile-landscape {
      &::after {
        height: 0.3rem;
      }
    }
  }
}
