:root {
  // Fonts
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-size-ultrasmall: 0.9rem;
  --font-size-xxxxs: 1.1rem;
  --font-size-xxxs: 1.2rem;
  --font-size-xxs: 1.3rem;
  --font-size-xs: 1.4rem;
  --font-size-sm: 1.5rem;
  --font-size-base: 1.6rem;
  --font-size-mmd: 1.7rem;
  --font-size-md: 1.8rem;
  --font-size-lg: 2rem;
  --font-size-llg: 2.2rem;
  --font-size-lllg: 2.3rem;
  --font-size-xl: 2.4rem;
  --font-size-xxl: 2.5rem;
  --font-size-xxxl: 3rem;
  --font-size-xxxxl: 3.2rem;
  --font-size-heavy: 3.5rem;
  --font-size-big: 4rem;
  --font-size-extra: 4.5rem;
  --font-size-huge: 5rem;
  --font-size-great: 6rem;
  --font-size-giant: 6.4rem;
  --ion-font-family: 'Inter', sans-serif;
  --font-family-italic: 'Times New Roman', italic;

  // Colors Example
  --success: 17, 158, 50; //#119e32
  --danger: 250, 0, 0;
  --danger-bg: 255, 248, 248;
  --warning: 253, 126, 20; // #fd7e14
  --transparent: transparent;

  // Grayscale
  --gray: 255, 255, 255;
  --gray-step-50: 254, 252, 252;
  --gray-step-100: 251, 251, 251;
  --gray-step-150: 250, 250, 250;
  --gray-step-200: 246, 246, 246;
  --gray-step-250: 245, 245, 245;
  --gray-step-300: 242, 242, 242;
  --gray-step-320: 243, 243, 243;
  --gray-step-350: 240, 240, 240;
  --gray-step-400: 239, 239, 239;
  --gray-step-450: 235, 235, 235;
  --gray-step-500: 230, 230, 230;
  --gray-step-520: 220, 220, 220;
  --gray-step-550: 214, 214, 214; // rgba(0, 0, 0, 0.16)
  --gray-step-600: 213, 213, 213;
  --gray-step-650: 210, 210, 210;
  --gray-step-700: 204, 204, 204;
  --gray-step-750: 196, 196, 196;
  --gray-step-800: 145, 145, 145;
  --gray-step-820: 129, 129, 129;
  --gray-step-850: 117, 117, 117;
  --gray-step-900: 112, 112, 112;
  --gray-step-925: 60, 60, 60;
  --gray-step-950: 19, 19, 19;
  --gray-step-1000: 31, 31, 31;
  --gray-step-1050: 0, 0, 0;
  --yellow: 245, 205, 7;
  --red: 255, 0, 0;
  --dark-blue: 0, 0, 153;
  --green: 139, 191, 144;
  --green-dark: 73, 140, 39;
  --green-blue: 87, 146, 144;
  --dark-purple: 38, 18, 45;
  --orange: 235, 103, 58;
  --required: 254, 78, 31;
  --cadet-blue: 0, 123, 125;
  --cadet-blue-dark: 177, 203, 204;
  --color-facebook: 66, 103, 178;
  --color-twitter: 0, 172, 238;
  --color-whatsapp: 37, 211, 102;
  --color-primary: 0, 0, 153;
  --color-skyblue: 0, 126, 255;

  // Times Example
  --slow: 500ms;
  --normal: 250ms;
  --fast: 150ms;

  // Layers Example
  --modal: 999;
  --overtop: 99;
  --top: 25;
  --undertop: 2;
  --over: 1;
  --back: -1;

  // Opacity Example
  --invisible: 0;
  --hidden: 0.2;
  --medium: 0.5;
  --smooth: 0.7;
  --almost-visible: 0.96;
  --visible: 1;

  // Ionic Base
  --ion-color-primary: rgb(var(--color-primary));

  // Toolbar
  --ion-toolbar-background: rgb(var(--gray));
  --ion-toolbar-border-color: #d5d5d5;
  --ion-toolbar-color: rgb(var(--black));
  --ion-color: var(--ion-color-primary);

  // Grid
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0.5rem;

  // Categories

  // Primary
  --category-color-primary: #e40c84;
  --category-color-primary-hover: #f861b5;
  --category-color-primary-opacity-25: rgba(228, 12, 132, 25%);

  // Secondary
  --category-color-secondary: #7f00ff;
  --category-color-secondary-hover: #9f6dd2;
  --category-color-secondary-opacity-25: rgba(127, 0, 255, 25%);

  // Tertiary
  --category-color-tertiary: #00b7b7;
  --category-color-tertiary-hover: #87c6c6;
  --category-color-tertiary-opacity-25: rgba(0, 183, 183, 25%);

  // Quaternary
  --category-color-quaternary: #ff6431;
  --category-color-quaternary-hover: #f99a7a;
  --category-color-quaternary-opacity-25: rgba(255, 100, 49, 25%);

  font-size: 62.5%; // To use rem as 10px;
  font-weight: var(--font-regular);
}
