.general {
  &__showmap-button {
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);

    i {
      font-size: var(--font-size-md);
      margin-right: 0.8rem;
    }
  }
}
