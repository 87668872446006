.modal-adventages {
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-xxl);
    font-weight: var(--font-extrabold);
    margin-bottom: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;

    @include mobile-landscape {
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    }
  }

  &__subtitle {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-md);
    font-weight: var(--font-bold);
    padding-left: 4rem;
    padding-right: 4rem;

    @include mobile-landscape {
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    }
  }

  &__card {
    align-items: center;
    border: 0.1rem solid rgb(var(--gray-step-800));
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 1.5rem 3rem;
    row-gap: 2.5rem;
    text-align: center;
    width: 25rem;

    @include tablet {
      padding: 2rem 2rem 3rem;
      width: 30rem;
    }
  }

  &__card-icon {
    height: 7rem;
    width: 7rem;
  }

  &__card-title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-bold);
    white-space: nowrap;
  }

  &__card-description {
    color: rgb(var(--gray-step-1000));
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    line-height: 2.2rem;
  }

  &__slide {
    margin-right: 2rem;

    @include mobile-landscape {
      margin-right: 4rem;
    }
  }

  &__swiper {
    padding: 0 4rem;
    width: 100%;
  }

  &__swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    padding-bottom: 6rem;
    position: relative;
    transition-property: transform;
    width: 100%;
    z-index: var(--over);
  }
}
