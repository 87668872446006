.days-selector {
  column-gap: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  max-width: 39rem;
  position: relative;
  row-gap: 2rem;

  &__label {
    background: rgb(var(--gray-step-250));
    border-radius: 100%;
    color: rgb(var(--gray-step-800));
    cursor: pointer;
    display: inline-block;
    font-size: var(--font-size-xs);
    font-weight: var(--font-regular);
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    transition: background var(--normal) ease-in-out;
    user-select: none;
    width: 4rem;

    @include mobile-landscape {
      font-size: var(--font-size-md);
      height: 4.7rem;
      line-height: 4.7rem;
      width: 4.7rem;
    }
  }

  &__checkbox {
    left: -9999rem;
    position: absolute;
    visibility: hidden;

    &:checked + .days-selector__label {
      background: rgb(var(--gray-step-600));
      color: rgb(var(--gray-step-1000));
    }
  }

  @include mobile-landscape {
    column-gap: 2rem;
  }
}
