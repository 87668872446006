.modal-categories {
  &__categories {
    color: rgb(var(--gray));
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 1.5rem;
  }

  &__title {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-semibold);
  }

  &__icon {
    font-size: var(--font-size-great);
  }

  &__category {
    border-radius: 1rem;
    column-gap: 2rem;
    display: flex;
    height: 100%;
    justify-content: center;
    place-items: center;
  }
}
