.activity-share {
  position: relative;
  right: initial;
  top: initial;
  z-index: 1;

  & .fab-list-side-start {
    align-items: baseline;
    margin-inline: 4.4rem;
  }

  &__share {
    --background: rgba(var(--color-primary), 50%);
    --color: rgb(var(--gray));

    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  &__social {
    border-radius: 100%;
    padding-top: 1.5rem !important;

    & i {
      color: rgb(var(--gray));
      font-size: 2.5rem !important;
    }

    &--facebook {
      background-color: rgb(var(--color-facebook));
    }

    &--twitter {
      background-color: rgb(var(--color-twitter));
    }

    &--whatsapp {
      background-color: rgb(var(--color-whatsapp));
    }

    &--copy {
      background-color: rgb(var(--orange));
    }
  }

  &__fab {
    --background-activated: var(--transparent);
    --background-focused: var(--transparent);
    --background-hover: var(--transparent);

    opacity: 0.9;
    transition: transform 0.1s ease-in-out;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }

    &--facebook {
      --background: rgb(var(--color-facebook));
    }

    &--twitter {
      --background: rgb(var(--color-twitter));
    }

    &--whatsapp {
      --background: rgb(var(--color-whatsapp));
    }

    &--copy {
      --background: rgb(var(--orange));
    }
  }
}
