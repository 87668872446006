.activity-details {
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  padding: 0 2rem;
  row-gap: 4.5rem;
  width: 100%;

  &__block {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);

    &--teachers {
      row-gap: 2.7rem;
    }

    &--teacher {
      row-gap: 1.2rem;
    }
  }

  &__block-title {
    align-items: center;
    color: var(--ion-color-primary);
    column-gap: 0.7rem;
    display: flex;
    font-size: var(--font-size-md);
    font-weight: var(--font-semibold);
    margin-bottom: 1.5rem;

    &--display-block {
      display: block;
    }

    @include mobile-landscape {
      column-gap: 1.2rem;
      font-size: var(--font-size-lg);
      font-weight: var(--font-bold);
    }
  }

  &__teacher-image-container {
    display: flex;
    justify-content: center;
  }

  &__block-icon {
    font-size: var(--font-size-xxl);

    @include mobile-landscape {
      font-size: var(--font-size-xxxl);
    }
  }

  &__categories {
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  &__title {
    color: rgb(var(--step-gray-1050));
  }

  &__image {
    align-items: center;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-radius: 1rem;
    display: flex;
    height: 35.4rem;
    place-content: center;
    position: relative;
    width: 100%;
  }

  &__academy {
    background: rgb(var(--gray));
    border: 1px solid rgb(var(--gray-step-700));
    border-radius: 9.6rem;
    height: 9.6rem;
    overflow: hidden;
    padding: 0;
    width: 9.6rem;
  }

  &__academy-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100%;
    width: 100%;
  }

  &__reserve {
    align-items: center;
    column-gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 3rem;

    @include mobile-landscape {
      row-gap: 1.5rem;
    }
  }

  &__button-reserve {
    flex: auto;
    margin: 0;
    max-width: 30rem;

    &--midpage {
      align-self: center;
      max-width: inherit;
      width: 100%;
    }
  }

  &__labels {
    column-gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1.5rem;

    @include mobile-landscape {
      column-gap: 1.5rem;
    }
  }

  &__label {
    color: rgb(var(--gray-step-1050));
    font-weight: var(--font-bold);

    @include mobile-landscape {
      padding: 1.5rem 3rem;
    }
  }

  &__description {
    line-height: 3rem;
  }

  &__equipment {
    background: rgb(var(--gray));
    border: 0.1rem solid rgb(var(--gray-step-520));
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    overflow-wrap: anywhere;
    padding: 3rem 2rem 5rem;
    row-gap: 2rem;
  }

  &__equipment-item {
    column-gap: 1rem;
    display: flex;

    i {
      font-size: var(--font-size-xs);
      padding-top: 3px;
    }
  }

  &__academy-info {
    background: rgb(var(--gray));
    border: 0.1rem solid rgb(var(--gray-step-520));
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 5rem;
    row-gap: 2rem;

    @include mobile-landscape {
      padding: 5rem 4.5rem 8rem;
    }
  }

  &__map {
    align-items: center;
    background-image: url('/assets/img/maps.webp');
    background-size: cover;
    border: 1px solid rgb(var(--gray-step-520));
    border-radius: 1rem;
    display: flex;
    height: 26.8rem;
    justify-content: center;
  }

  &__pin {
    color: var(--ion-color-primary);
    font-size: 7.4rem;
  }

  &__teacher-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8rem;
    height: 9rem;
    width: 9rem;

    &--icon {
      font-size: 8rem;
    }
  }

  &__teacher {
    font-weight: var(--font-medium);
  }

  &__interest {
    align-items: center;
    flex-direction: column;
  }

  @include mobile-landscape {
    row-gap: 10rem;
  }
}
