.happening {
  max-width: 167rem;
  width: 100%;

  &__title {
    color: var(--ion-color-primary);
    display: inline-block;
    font-size: var(--font-size-xl);
    font-weight: var(--font-extrabold);
    max-width: 23.3rem;

    @include mobile-landscape {
      font-size: var(--font-size-xxxl);
      max-width: inherit;
    }
  }

  &__swiper {
    --swiper-navigation-color: rgb(var(--gray-step-1050));

    padding: 0 2rem;

    .swiper-button-prev {
      background: white;
      border: 1px solid rgb(var(--gray-step-700));
      border-radius: 100%;
      color: var(--ion-color-primary);
      height: 6rem;
      padding: 1.5rem;
      width: 6rem;

      &::after {
        content: '\f104';
        font: var(--fa-font-solid);
        font-size: var(--font-size-lg);
      }
    }

    .swiper-button-next {
      background: white;
      border: 1px solid rgb(var(--gray-step-700));
      border-radius: 100%;
      color: var(--ion-color-primary);
      height: 6rem;
      padding: 1.5rem;
      width: 6rem;

      &::after {
        content: '\f105';
        font: var(--fa-font-solid);
        font-size: var(--font-size-lg);
      }
    }
  }

  &__slide {
    display: flex;
    margin-right: 2rem;
  }
}
