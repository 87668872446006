.sessions {
  &__day-row {
    display: flex;
    justify-content: center;
    row-gap: 1rem;

    @include tablet-landscape {
      justify-content: start;
    }
  }

  &__form {
    max-width: 141rem;
  }

  &__table {
    height: 100%;
    width: 100%;
  }

  &__table-f {
    height: 100%;
    width: 100%;
  }

  &__table-actions {
    --padding-start: 1rem !important;
    --padding-end: 1rem !important;

    font-size: var(--font-size-xs);
  }

  &__table-capacitycol {
    width: 8rem;
  }

  &__table-actionscol {
    width: 31rem;
  }

  &__schedules-datetype {
    font-weight: var(--font-medium);
    margin-bottom: 0.5rem;
  }

  &__schedules {
    display: flex;
    flex-direction: column;
  }

  &__day-col {
    border-left: 0;
    padding-left: 0;

    @include tablet-landscape {
      border-left: 1px solid rgb(var(--gray-step-500));
      padding: 2rem;
    }
  }

  &__day {
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--regular);
    text-align: left;
    width: 11.1rem;

    & div {
      white-space: nowrap;
    }

    @include tablet-landscape {
      flex-direction: column;
      text-align: right;
    }
  }

  &__sub {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--regular);
    justify-content: center;

    &--mobile {
      flex-wrap: wrap;
      justify-content: center;
    }

    @include tablet-landscape {
      justify-content: start;
    }
  }

  &__sub-label {
    font-size: var(--font-size-base);
    font-weight: var(--regular);
  }

  &__sub-item {
    --min-height: 4.5rem;

    width: 8.7rem;
  }

  &__sub-item-birth {
    --min-height: 4.5rem;

    width: 6.5rem;
  }

  &__sub-input {
    --padding-start: 0.5rem !important;
    --padding-end: 0rem !important;
    --padding-top: 0.5rem !important;
    --padding-bottom: 0.5rem !important;
    --padding-inline-start: 0 !important;
    --padding-inline-end: 0 !important;

    font-size: var(--font-size-base);
  }

  &__sub-buttongroup {
    display: grid;
  }

  &__sub-button {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    --padding-inline-start: 0 !important;
    --padding-inline-end: 0 !important;

    font-size: var(--font-size-base);
    height: 2.8rem;

    &--dayplan {
      font-size: var(--font-size-xs);
      height: 2rem;
    }

    &::part(native) .button-inner {
      justify-content: flex-start !important;
    }

    @include tablet-landscape {
      --padding-start: 1rem !important;
    }
  }

  &__customdate {
    display: inline-grid;
    max-width: 35rem;
  }

  &__schedule {
    align-items: center;
    border-bottom: 3px solid rgba(var(--color-primary), 40%);
    color: rgba(var(--color-primary), 100%);
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);
    max-width: 13rem;
    padding: 2rem 0;

    & i {
      font-size: var(--font-size-lg);
    }

    @include tablet-landscape {
      font-size: var(--font-size-md);
    }
  }

  &__pagination {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &__pagination-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);

    @include tablet-landscape {
      font-size: var(--font-size-md);
    }
  }

  &__pages {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    max-width: 20rem;

    @include mobile-landscape {
      max-width: 50rem;
    }
  }

  &__extreme-pages {
    align-items: center;
    display: flex;
  }

  &__row-editor {
    border-bottom: 0.1rem dashed rgb(var(--gray-step-500));
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    row-gap: 1rem;
  }

  &__day-block {
    background: rgba(var(--color-primary), 5%);
    border-radius: 1.5rem;
    padding: 1rem;
    row-gap: 1rem;

    &--no-schedules {
      background: rgba(var(--red), 5%);
    }
  }

  &__no-schedules {
    color: rgba(var(--red), 80%);
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);
    padding: 1rem 0 0;
  }

  &__no-schedules-month {
    align-items: center;
    color: rgb(var(--orange));
    column-gap: 1rem;
    display: flex;
    font-size: var(--font-size-base);

    & i {
      font-size: var(--font-size-lg);
    }
  }

  &__new-container {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-xs);
    font-weight: var(--font-medium);
    row-gap: 0.5rem;
  }

  &__new-button {
    max-width: 15rem;
  }
}
