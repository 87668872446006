.welcome {
  max-width: 94rem;
  padding: 0 2rem;
  width: 100%;

  &__title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-extra);
    font-weight: var(--font-extrabold);
    margin: 0;
  }

  &__bg {
    background: rgb(var(--gray-step-150)) url('/assets/img/bg_mobile.webp')
      no-repeat;
    background-size: contain;

    @include tablet {
      background: rgb(var(--gray-step-150)) url('/assets/img/bg_desktop.png')
        no-repeat;
      background-position-x: center;
      background-position-y: -100px;
      background-size: contain;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    row-gap: 2rem;

    @include mobile-landscape {
      padding-top: 16rem;
      row-gap: 5rem;
    }
  }

  &__cards {
    column-gap: 1.6rem;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1rem));
    place-content: center;
    place-items: center;
    row-gap: 1.6rem;

    @include tablet-landscape {
      grid-template-columns: repeat(4, 21.1rem);
    }
  }

  &__card-icon {
    fill: rgb(var(--gray));
    height: 3.7rem;
    width: 3.7rem;

    @include tablet {
      height: 4.7rem;
      width: 4.7rem;
    }
  }

  &__card-title {
    color: rgb(var(--gray));
    font-size: var(--font-size-mmd);
    font-weight: var(--font-semibold);
    white-space: nowrap;
  }

  &__card {
    border-radius: 1rem;
    color: rgb(var(--gray));
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 9rem;
    place-content: center;
    place-items: center;
    row-gap: 1rem;
    width: 100%;

    &--plansoftheday {
      background: var(--category-color-primary);

      &:hover {
        background: var(--category-color-primary-hover);
      }
    }

    &--camps {
      background: var(--category-color-secondary);

      &:hover {
        background: var(--category-color-secondary-hover);
      }
    }

    &--birthdays {
      background: var(--category-color-tertiary);

      &:hover {
        background: var(--category-color-tertiary-hover);
      }
    }

    &--extracurricular {
      background: var(--category-color-quaternary);

      &:hover {
        background: var(--category-color-quaternary-hover);
      }
    }

    @include mobile-landscape {
      height: 10.5rem;
    }
  }

  &__search {
    margin-top: 0;
    max-width: 92rem !important;
  }
}
