.onboarding-modal {
  background: rgb(var(--gray-step-400));

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    row-gap: 3.5rem;
    text-align: center;
  }

  &__header-title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-medium);
  }

  &__body {
    padding: 0 2rem 6rem;

    @include mobile-landscape {
      padding: 0 4rem 6rem;
    }
  }

  &__age-selector {
    align-items: center;
    background: rgb(var(--gray));
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    row-gap: 5rem;

    @include mobile-landscape {
      padding: 4rem;
    }
  }
}
