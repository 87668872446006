.discovery {
  max-width: 167rem;
  width: 100%;

  &__title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-xxl);
    font-weight: var(--font-extrabold);
    margin-bottom: 3rem;
    padding-left: 2rem;
    text-align: center;

    @include mobile-landscape {
      font-size: var(--font-size-xxxl);
      margin-bottom: 5rem;
      text-align: left;
    }
  }

  &__no-themes-container {
    font-size: var(--font-size-lg);
    font-weight: var(--font-semibold);
    padding: 1.5rem;
    text-align: center;

    @include tablet {
      font-size: var(--font-size-xxl);
    }
  }

  &__link {
    cursor: pointer;
  }

  &__slide {
    display: flex;
    margin-right: 0.5rem;

    @include mobile-landscape {
      margin-right: 2.5rem;
    }
  }

  &__swiper {
    --swiper-navigation-color: rgb(var(--gray-step-1050));

    padding-left: 2rem;

    .swiper-button-prev {
      background: white;
      border: 1px solid rgb(var(--gray-step-700));
      border-radius: 100%;
      height: 3.5rem;
      padding: 1.5rem;
      width: 3.5rem;

      &::after {
        content: '\f104';
        font: var(--fa-font-solid);
        font-size: var(--font-size-sm);
      }
    }

    .swiper-button-next {
      background: white;
      border: 1px solid rgb(var(--gray-step-700));
      border-radius: 100%;
      height: 3.5rem;
      padding: 1.5rem;
      width: 3.5rem;

      &::after {
        content: '\f105';
        font: var(--fa-font-solid);
        font-size: var(--font-size-sm);
      }
    }
  }

  &__card {
    align-items: center;
    background: rgb(var(--gray));
    border: 0.1rem solid rgb(var(--gray-step-450));
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 9.6rem;
    opacity: var(--almost-visible);
    place-content: center;
    row-gap: 1rem;
    text-align: center;
    width: 9.6rem;

    @include mobile-landscape {
      height: 11rem;
      width: 11rem;
    }
  }

  &__icon {
    fill: var(--ion-color-primary);
    height: 3.6rem;
    width: 3.6rem;

    @include mobile-landscape {
      height: 5.5rem;
      width: 5.5rem;
    }
  }

  &__name {
    color: var(--ion-color-primary);
    font-size: 1.2rem;
    font-weight: var(--font-semibold);
    white-space: nowrap;

    @include mobile-landscape {
      font-size: 1.3rem;
    }
  }

  &__arrows-disable {
    display: none;
  }
}
