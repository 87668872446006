h1 {
  color: var(--ion-color-primary);
  font-size: var(--font-size-huge);
  font-weight: var(--font-extrabold);
  margin: 0;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

h2 {
  color: var(--ion-color-primary);
  font-size: var(--font-size-xl);
  font-weight: var(--font-bold);
}

h3 {
  color: var(--ion-color-primary);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-extrabold);
  margin: 0;
  word-break: break-all;

  @include mobile-landscape {
    font-size: var(--font-size-xxxl);
  }
}

h4 {
  color: var(--ion-color-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-bold);
  margin: 0;
}

.color-primary {
  color: var(--ion-color-primary);
}

.color-disabled {
  color: rgb(var(--gray-step-800));
}

.bold {
  font-weight: var(--font-bold);
}

.container {
  display: flex;
  justify-content: center;
  position: relative;

  &--map {
    justify-content: flex-end;
  }

  &__center {
    max-width: 165rem;
    padding: 0 2rem;
    position: relative;
    width: 100%;
  }

  &__center-map {
    max-width: 95rem;
    padding: 0 2rem;
    position: relative;
    width: 100%;
  }

  &--default-bg {
    background-color: rgb(var(--gray-step-150));
  }
}

.fade-in {
  animation: fade-in 0.3s ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes glow {
  0% {
    color: inherit;
    text-shadow: 0 0 0.5rem var(--transparent);
  }

  100% {
    color: var(--ion-color-primary);
    text-shadow: 0 0 2rem var(--ion-color-primary);
  }
}

.vh-fullh {
  height: 100vh;
}

.vh-mediumh {
  height: 25vh;
}

.min-vh {
  min-height: 50vh;
}

.container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100dvh - 13rem);
  position: relative;

  @include mobile-landscape {
    min-height: calc(100dvh - 9rem);
  }
}

@keyframes calendar-resume-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes calendar-resume-fade-out {
  to {
    opacity: 0;
  }
}

@keyframes calendar-resume-translate-in {
  0% {
    transform: translateY(-1.5rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes calendar-resume-translate-out {
  to {
    transform: translateY(-1.5rem);
  }
}

.ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
