.modal-payments {
  &__heading {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  &__checking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 18rem;
    row-gap: 2rem;
    text-align: center;
  }

  &__post-payment {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 16rem;
    row-gap: 2rem;
    text-align: center;
  }

  &__error {
    color: rgb(var(--red));
  }

  &__success {
    color: rgb(var(--green));
  }
}
