.activities {
  &__nodata {
    display: block;
    text-align: center;
  }

  &__filter {
    align-items: center;
    column-gap: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0 0.2rem;
    padding-left: 0;

    &--title {
      display: flex;
      gap: 10px;
    }

    @include mobile-landscape {
      margin: 4rem 0;
      padding-left: 1rem;
    }
  }

  &__cards {
    --ion-grid-column-padding: 1rem;

    ion-row {
      row-gap: 2rem;

      @include mobile-landscape {
        row-gap: 7.5rem;
      }
    }
  }

  &__title {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-extrabold);
  }

  &__order-button {
    --border-width: 0;
    --color: rgb(var(--gray-step-1050));

    max-height: 3.6rem;

    &--no-border {
      --border-width: 0;
    }

    @include mobile-landscape {
      --border-width: 0.1rem;
      --color: var(--ion-color-primary);

      &--no-border {
        --border-width: 0;
      }
    }
  }

  &__order-item {
    --padding-start: 1rem;
    --inner-padding-end: 0;
    --border-color: var(--transparent);

    border-radius: 0.4rem;
    cursor: pointer;

    &:hover {
      --background: rgb(var(--gray-step-400));
      --color: var(--ion-color-primary);
    }

    &--active {
      --background: rgb(var(--gray-step-600));

      color: var(--ion-color-primary);
      font-weight: var(--font-medium);
    }
  }
}

.a-map-container {
  position: fixed;
  //flex-grow: 1;
  width: 45%;
  z-index: 10;

  &--fullscreen {
    width: 100%;
  }

  @include tablet {
    position: initial;
    z-index: 1;
  }
}

.a-map {
  --map-height: 100%;
  --map-top: 0;

  height: var(--map-height);
  position: sticky;
  right: 0;
  top: var(--map-top);
  transition: height 0.1s ease-in-out, width 0.2s ease-in-out;
  z-index: 0;

  &--closing {
    height: 0;
  }

  &__close-container {
    bottom: 2rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &__close-button {
    --background: rgb(var(--gray-step-1050));
    --background-hover: rgba(var(--gray-step-1050), 0.5);

    font-size: var(--font-size-xs);
    font-weight: var(--font-bold);
  }
}
