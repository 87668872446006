.uploader {
  --min-height-mobile: 6.6rem;
  --min-width-mobile: 6.6rem;
  --min-height-desktop: 10rem;
  --min-width-desktop: 10rem;
  --border-radius-circle: 100%;
  --border-radius-square: 1rem;
  --text-font-mobile: var(--font-size-ultrasmall);
  --text-font-desktop: var(--font-size-xxs);
  --icon-font-mobile: 1.9rem;
  --icon-font-desktop: 2.7rem;
  --min-height: var(--min-height-mobile);
  --min-width: var(--min-width-mobile);
  --border-radius: var(--border-radius-circle);

  align-items: center;
  column-gap: 2rem;
  display: flex;

  &--square {
    --min-width: 14rem !important;
    --min-height: 14rem !important;
    --border-radius: var(--border-radius-square);
    --text-font-mobile: var(--text-font-desktop);
    --icon-font-mobile: var(--icon-font-desktop);
  }

  &__drop {
    align-items: center;
    background-color: rgb(var(--gray-step-250));
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: var(--min-height);
    min-width: var(--min-width);
    overflow: hidden;

    &--error {
      border-color: rgb(var(--danger)) !important;

      & * {
        color: red !important;
      }
    }

    &--required {
      border: 0.1rem solid rgb(var(--required));

      & * {
        color: rgb(var(--required)) !important;
      }
    }

    &--dropzone {
      border: 0.1rem solid var(--ion-color-primary);
    }

    &--dropzone-hover {
      border: dotted 0.3rem var(--ion-color-primary);
    }
  }

  &__error {
    color: red;
    font-size: var(--font-size-xs);
    word-break: break-word;
  }

  &__upload {
    display: flex;
    justify-content: center;
    min-height: var(--min-height);
    min-width: var(--min-width);
    overflow: hidden;
    position: relative;
  }

  &__button {
    align-items: center;
    background: var(--transparent);
    color: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    font-size: var(--text-font-mobile);
    justify-content: center;
    row-gap: 0.5rem;

    i {
      font-size: var(--icon-font-mobile);

      @include tablet {
        font-size: var(--icon-font-desktop);
      }
    }

    @include tablet {
      font-size: var(--text-font-desktop);
    }
  }

  &__input {
    display: none;
  }

  @include tablet {
    --min-height: var(--min-height-desktop);
    --min-width: var(--min-width-desktop);
  }
}
