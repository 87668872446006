.activity {
  --activity-border: 0.1rem solid rgb(var(--gray-step-700));

  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  max-width: 43.6rem;
  min-width: 38.5rem;
  position: relative;

  &__actions {
    column-gap: 1rem;
    display: flex;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__favorite {
    align-items: center;
    background: rgba(var(--color-primary), 50%);
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 12%) 0 4px 16px 0;
    color: rgb(var(--gray));
    cursor: pointer;
    display: flex;
    font-size: 2.2rem;
    height: 4rem;
    justify-content: center;
    width: 4rem;
    z-index: 1;

    &:hover {
      background: var(--ion-color-primary-tint, #4c8dff);
    }
  }

  &--resizable {
    min-width: auto;
  }

  &--bg-white {
    background-color: rgb(var(--gray));
  }

  &--compact {
    max-width: 30.4rem !important;
    min-width: 30.4rem;
  }

  &__body {
    background-color: rgb(var(--gray));
    border-bottom: var(--activity-border);
    border-left: var(--activity-border);
    border-radius: 0 0 1rem 1rem;
    border-right: var(--activity-border);
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &--compact {
      padding: 2rem;
    }
  }

  &__image {
    align-items: center;
    background-position-x: 50% !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    height: 19.53rem;
    place-content: center;
    position: relative;
    width: 100%;

    &--compact {
      height: 20rem;
    }

    &--cursor-pointer {
      cursor: pointer;
    }
  }

  &__academy {
    background: rgb(var(--gray));
    border: 1px solid rgb(var(--gray-step-700));
    border-radius: 7rem;
    height: 7.1rem;
    overflow: hidden;
    padding: 0;
    width: 7.1rem;
  }

  &__academy-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }

  &__imagefooter {
    bottom: 1.5rem;
    color: rgb(var(--gray));
    display: flex;
    left: 1.5rem;
    position: absolute;
  }

  &__category {
    color: var(--category-color-primary);
    font-size: var(--font-size-xs);
    font-weight: var(--font-bold);
    margin-bottom: 0.3rem;

    &--camps {
      color: var(--category-color-secondary);
    }

    &--plans {
      color: var(--category-color-primary);
    }

    &--birthday {
      color: var(--category-color-tertiary);
    }

    &--extra {
      color: var(--category-color-quaternary);
    }

    &--compact {
      color: rgb(var(--gray-step-50));
      font-weight: var(--font-regular);
    }
  }

  &__title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-bold);
    -webkit-line-clamp: 1;
    margin-bottom: 1.3rem;

    &--cursor-pointer {
      cursor: pointer;
    }
  }

  &__building {
    color: rgb(var(--dark-blue));
    font-size: var(--font-size-xs);
    font-weight: var(--font-regular);
    margin-bottom: 1.7rem;
    max-width: 31rem;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__stars {
    column-gap: 0.5rem;
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__score {
    color: var(--gray-step-1050);
    font-size: var(--font-size-xs);
  }

  &__city {
    color: var(--gray-step-1050);
    font-size: var(--font-size-xs);
    margin-bottom: 0.9rem;
  }

  &__schedule {
    color: var(--gray-step-1050);
    font-size: var(--font-size-xs);
    -webkit-line-clamp: 1;
    margin-bottom: 2rem;
  }

  &__footer {
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 1rem;

    &--compact {
      column-gap: 1.5rem;
      display: grid;
      grid-template-columns: 12.5rem 12.5rem;
      row-gap: 1.25rem;
    }
  }

  &__label {
    background: rgb(var(--gray-step-400));
    border-radius: 2rem;
    color: var(--gray-step-1050);
    flex-grow: 1;
    font-size: var(--font-size-xs);
    font-weight: var(--font-bold);
    height: 3rem;
    max-width: 12rem;
    padding: 0.7rem;
    text-align: center;
    white-space: nowrap;

    &--compact {
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      font-weight: var(--font-regular);
      height: 6rem;
      max-width: 100%;
      place-content: center;
      place-items: center;
    }

    &--special-label {
      flex-grow: 0;
      font-size: var(--font-size-xxs);
      height: 5rem;
      max-width: 12rem;
      padding: 0.7rem 2rem;
    }
  }

  &__labelvalue {
    font-size: var(--font-size-md);
    font-weight: var(--font-bold);

    &--small {
      font-size: var(--font-size-xs);
    }
  }

  &__reserve {
    flex-grow: 1;

    &--compact {
      grid-column: span 2;
    }
  }

  @include mobile-landscape {
    max-width: 38.5rem;
  }
}

.activity-schedule {
  color: var(--ion-color-primary);
  cursor: pointer;
  display: inline;

  &--no-tooltip {
    color: rgb(var(--gray-step-1050));
    cursor: default;
  }

  &:hover {
    opacity: 0.9;
  }

  &__type {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-sm);
    font-weight: var(--font-semibold);
    margin-bottom: 1rem;
  }

  &__days {
    display: grid;
    min-width: 10rem;
    text-align: center;
  }
}
