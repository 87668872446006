.activity-table {
  &__category-header {
    visibility: hidden;
    width: 20rem;
  }

  &__table-container {
    &--no-overflow {
      @include tablet {
        display: flex;
        justify-content: center;
        overflow-x: auto;
      }
    }

    @include tablet {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        background: transparent;
        width: 0;
      }
    }
  }

  &__inner-table {
    height: 100%;
    width: 100%;
  }

  &__session-holder {
    background-color: rgb(var(--gray));
    padding: 0;
  }

  &__category-title {
    font-weight: var(--font-semibold);
  }

  &__activity-title {
    font-weight: var(--font-bold);

    &--link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }

  &__header-10 {
    width: 10rem;
  }

  &__header-15 {
    width: 15rem;
  }

  &__promoted-icon {
    &--success {
      color: rgb(var(--success));
    }

    &--danger {
      color: rgb(var(--danger));
    }
  }

  &__input {
    --placeholder-color: rgb(var(--gray-step-1000));

    cursor: pointer;

    & input {
      cursor: pointer;
    }
  }

  &__select {
    min-width: 30rem;

    .ng-input {
      font-size: var(--font-size-md);
      padding: 1.2rem 3.8rem !important;
      top: 0 !important;
    }

    &.ng-select-opened {
      & .ng-select-container {
        border-top-left-radius: 2.3rem;
        border-top-right-radius: 2.3rem;
      }
    }

    & .ng-select-container {
      background-color: rgb(var(--gray-step-250));
      border-color: rgb(var(--gray-step-600));
      color: rgb(var(--gray-step-1000));
      padding: 1.2rem 3rem;

      & .ng-value-container {
        & .ng-placeholder {
          color: rgb(var(--gray-step-1000));
          font-size: var(--font-sm);
        }
      }
    }
  }

  &__category {
    border-radius: 99rem;
    color: rgb(var(--gray-step-925));
    font-weight: var(--font-bold);
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    text-align: center;

    &--extracurricular {
      background-color: var(--category-color-quaternary-opacity-25);
    }

    &--camps {
      background-color: var(--category-color-secondary-opacity-25);
    }

    &--plans {
      background-color: var(--category-color-primary-opacity-25);
    }

    &--birthday {
      background-color: var(--category-color-tertiary-opacity-25);
    }
  }

  &__activity-container {
    background-color: rgb(var(--gray-step-500));
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &--sessions {
      row-gap: 1rem;
    }
  }

  &__text-title {
    color: rgb(var(--cadet-blue));
    font-size: var(--font-size-lg);

    &--category {
      color: rgb(var(--gray-step-925));
      font-size: var(--font-size-xl);
    }
  }

  &__text-info {
    font-size: var(--font-size-md);

    &--disabled {
      color: rgb(var(--gray-step-800)) !important;
    }

    &--link {
      color: var(--ion-color-primary);
    }
  }

  &__button {
    --color: var(--ion-color-primary);
  }
}
