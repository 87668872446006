.user-reserves {
  &__vote-cell {
    height: 100%;
  }

  &__info-cell {
    background-color: transparent !important;
  }

  &__container {
    max-width: 125rem;
  }

  &__vote-container {
    min-width: 12rem;
  }

  &__cancelation-container {
    padding: 1rem;
    text-align: center;
  }

  &__star {
    & .stars {
      display: block;

      & i {
        font-size: var(--font-size-xxxl);
      }
    }
  }
}
