.activity-reserves {
  &__summary {
    background-color: rgb(var(--gray-step-450));
    border-radius: 7rem;
    column-gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 3rem;
    row-gap: 2rem;
    text-align: center;
  }

  &__summary-block {
    display: grid;
    flex-flow: column nowrap;
    grid-template-rows: auto 1fr;
    row-gap: 1rem;
  }

  &__summary-title {
    color: rgb(var(--green-blue));
    font-size: 2rem;
    font-weight: var(--font-bold);
  }

  &__summary-description {
    color: rgb(var(--gray-step-1050));
    display: grid;
    font-size: 1.5rem;
    place-items: center;

    &--quantity {
      font-size: 2rem;
      font-weight: var(--font-semibold);
    }

    &--places {
      color: rgb(var(--green-dark));
      font-size: 2.5rem;
      font-weight: var(--font-bold);
    }
  }

  &__min-width {
    min-width: 14rem;
  }

  &__options-container {
    column-gap: 1rem;
    display: flex;
    flex-flow: column wrap;
    max-width: 30rem;

    &--schedule {
      align-items: center;
      column-gap: 2rem;
      flex-direction: row;
      margin-left: 1rem;

      @include tablet-landscape {
        flex-direction: row;
        margin-left: 0;
      }
    }

    &--calendar-schedules {
      max-width: 30rem;
    }

    @include tablet-landscape {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.reserve-status {
  font-size: var(--font-size-xs);

  &--pending {
    color: rgb(var(--orange));
  }

  &--canceled {
    color: rgb(var(--red));
  }

  &--confirmed {
    color: rgb(var(--green));
  }
}
