.admin-menu {
  --ion-grid-column-padding: 0;

  &__item {
    align-items: center;
    border: 0.1rem solid var(--ion-color-primary);
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;

    &:hover {
      background-color: rgba(var(--gray-step-800), 0.071);
      border-color: var(--transparent);
      /* stylelint-disable-next-line no-descending-specificity */
      & .admin-menu__image {
        fill: var(--ion-color-primary);
      }
    }

    &--disabled {
      background-color: rgb(var(--gray-step-200));
      border-color: inherit;
      cursor: auto;

      &:hover {
        background-color: inherit;
        border-color: inherit;

        & svg {
          fill: rgb(var(--gray-step-800));
        }
      }
    }

    &--current {
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0%) 0%,
        rgba(var(--green), 20%) 100%
      );
      color: rgb(var(--gray-step-900));
    }

    @include tablet {
      padding: 2.3rem 3rem;
    }
  }

  &__title {
    font-size: var(--font-size-base);
    font-weight: var(--font-bold);

    @include tablet {
      font-size: var(--font-size-lg);
    }
  }

  &__image {
    fill: rgb(var(--gray-step-800));
    height: 4rem;
    width: 4rem;

    @include tablet {
      height: 7rem;
      width: 7rem;
    }
  }

  &__col {
    padding: 1.25rem 0;

    @include tablet {
      padding: 2.5rem 5rem;
    }
  }

  &__username {
    display: block;
    font-size: var(--font-size-lg);
    font-weight: var(--font-bold);
    margin-bottom: 2rem;
  }
}
