.stars {
  --size: var(--font-size-md);
  --hover-position: 0;

  display: flex;

  &--interactive {
    &.stars__star {
      cursor: pointer;
    }
  }

  &__star {
    --width-percent: 0%;

    background-color: var(--transparent);
    color: rgba(210, 210, 210, 100%);
    font-size: var(--size);
    padding: 0 0.25rem;
    transition: color var(--normal) ease;

    &--clicked {
      animation: blink var(--normal) ease-in-out;
    }
  }

  &__star-icon {
    background-color: rgba(245, 205, 7, 100%);
    background-color: gray;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    height: var(--size);
    overflow: hidden;
    position: relative;
    width: var(--size);

    &::after {
      background-color: rgba(245, 205, 7, 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: var(--width-percent, 0%);
      width: max(
        var(--width-percent, 0%),
        calc(100% * (var(--hover-position) - var(--position) + 1))
      );
    }
  }
}

@keyframes blink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
