.admin {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100dvh - (8.1rem + 7.3rem));

  &::part(scroll) {
    overflow-y: auto !important;
  }

  &__header {
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: var(--ion-color-primary);
    display: none;
    height: 7rem;
    justify-content: center;
    margin-bottom: 1.75rem;
    padding: 0 2rem;
    position: sticky;
    top: 6rem;
    z-index: var(--top);

    &--academy {
      background-image: url('/assets/img/admin_header_academy.webp');
    }

    &--family {
      background-image: url('/assets/img/admin_header_family.webp');
    }

    &--visible {
      display: flex !important;
    }

    @include tablet {
      border-bottom: 0.1rem solid rgb(var(--gray-step-600));
      display: flex;
      height: 10rem;
      margin-bottom: 6rem;
      top: 9rem;
    }
  }

  &__header-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-bold);

    @include tablet {
      font-size: var(--font-size-xxl);
    }
  }

  &__header-subtitle {
    font-size: var(--font-size-sm);
    margin-left: 1rem;

    @include tablet {
      font-size: var(--font-size-lg);
    }
  }

  &__header-center {
    max-width: 159rem;
    padding: 0;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    margin-bottom: 20rem;
    padding: 0 2rem;
  }

  &__container-center {
    max-width: 163rem;
    width: 100%;
  }

  &__container-row {
    column-gap: 5rem;
  }

  &__disclaimer {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
  }

  &__disclaimer-required {
    border: 0.1rem solid rgb(var(--required));
    border-radius: 1.5rem;
    height: 3.4rem;
    margin-right: 1rem;
    width: 4.1rem;
  }

  &__input-max-width {
    max-width: 40rem;
  }

  &__link {
    color: var(--ion-color-primary);
    display: inline-block;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    text-decoration: none;
  }

  &__custom-back {
    color: var(--ion-color-primary);
    cursor: pointer;
    font-size: var(--font-size-big);
    position: relative;
    top: -1.4rem;
  }

  @include tablet {
    display: block;
    min-height: initial;
  }
}

.admin-section {
  min-width: 29rem;

  &--calendarfamiliar {
    display: grid;
    grid-template-rows: auto auto 1fr;
    min-height: 65rem;
  }

  &--calendar {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 78rem !important;

    @include mobile-landscape {
      min-height: 65rem;
    }
  }

  &__title {
    align-items: center;
    color: var(--gray-step-1050);
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-bold);
    justify-content: space-between;
    margin-bottom: 2.5rem;

    @include tablet {
      font-size: var(--font-size-lg);
      margin-bottom: 4rem;
    }
  }

  &__subtitle {
    color: var(--gray-step-1050);
    display: block;
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);
    margin-bottom: 2.7rem;

    &--bigger {
      font-size: var(--font-size-lg);
      font-weight: var(--font-semibold);
    }

    @include tablet {
      margin-bottom: 4rem;
    }
  }

  &__management-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
  }

  &__activity-name {
    color: var(--ion-color-primary);
  }

  &__activity-time {
    font-weight: var(--font-medium);
  }
}

.admin-tabs {
  border-bottom: 0.1rem solid rgb(var(--gray-step-600));
  column-gap: 5rem;
  display: flex;

  &__item--activity {
    color: var(--ion-color-primary) !important;
    font-size: var(--font-size-md) !important;
    font-weight: var(--font-semibold) !important;
    pointer-events: none !important;
  }

  &__item {
    color: rgb(var(--gray-step-800));
    cursor: pointer;
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);
    padding: 2.3rem 0;

    &--active {
      border-bottom: 3px solid rgb(var(--gray-step-1000));
      color: rgb(var(--gray-step-1000));
      pointer-events: none;
    }

    &--disabled {
      opacity: var(--medium);
      pointer-events: none;
      user-select: none;
    }

    &--glowing {
      animation-direction: alternate;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: glow;
    }
  }
}

.admin-tabs-menu {
  display: flex;
  flex-direction: column;

  &__item {
    align-items: center;
    border-top: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    justify-content: space-between;
    padding: 2.5rem 0;

    &:hover {
      color: var(--ion-color-primary);
      font-weight: var(--font-medium);
    }

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid #d5d5d5;
    }

    &::after {
      color: var(--ion-color-primary);
      content: '\f105';
      font: var(--fa-font-regular);
    }
  }
}
