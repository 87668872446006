.ng-select {
  //z-index: var(--undertop);

  &.ng-select-opened {
    & .ng-select-container {
      border-top-left-radius: 3.3rem;
      border-top-right-radius: 3.3rem;
    }
  }

  &.datarequired .ng-select-container {
    border-color: rgb(var(--required));
  }

  & .ng-select-container {
    border-color: rgb(var(--gray-step-800));
    border-radius: 4rem;
    border-width: 0.1rem;
    font-size: var(--font-size-md);
    height: auto !important;
    padding: 2rem 2.3rem;
  }

  & .ng-input {
    font-size: var(--font-size-md);
    padding: 1.5rem 3.3rem !important;
  }
}
