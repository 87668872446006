.toast {
  &--error {
    --border-color: rgb(var(--red));
    --border-width: 0.1rem;
    --border-style: solid;
    --background: #fff;
    --color: rgb(var(--red));
  }

  &--success {
    --border-color: rgb(var(--green));
    --border-width: 0.1rem;
    --border-style: solid;
    --background: #fff;
    --color: rgb(var(--green));
  }
}
