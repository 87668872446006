.toolbar-filters {
  background: rgb(var(--gray-step-300));
  display: flex;
  flex-direction: column;
  place-content: flex-end;
  row-gap: 1rem;

  &__thematics {
    max-width: 100vw;
    overflow-x: hidden;
    position: sticky;
    top: 5.3rem;
    z-index: var(--overtop);

    @include tablet {
      top: 9rem;
    }
  }

  &--subfilter {
    background: rgb(var(--gray-step-300));
    border-bottom: 0;
    height: 6.9rem;

    &:first-child {
      border-bottom: 1px solid rgb(var(--gray-step-600));
      box-shadow: 0 0.5rem 0.9rem -0.5rem rgb(187, 187, 187);
      position: relative;
    }

    @include mobile-landscape {
      box-shadow: none !important;
      height: 10.5rem;
    }
  }

  &__filter {
    align-self: center;
    max-width: 100vw;
    padding-left: 2rem;
  }

  &__search {
    width: 53.3rem;
  }

  &--search {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    padding: 0 2rem;

    @include tablet {
      column-gap: 2rem;
    }
  }
}
