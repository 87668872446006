.label {
  border: 0.1rem solid rgb(var(--gray-step-550));
  border-radius: 10rem;
  color: rgb(var(--gray-step-850));
  font-size: var(--font-size-xs);
  font-weight: var(--font-regular);
  padding: 0.6rem 1.5rem;
  text-align: center;

  &--plansoftheday {
    border-color: var(--category-color-primary);
    color: var(--category-color-primary);
  }

  &--camps {
    border-color: var(--category-color-secondary);
    color: var(--category-color-secondary);
  }

  &--birthdays {
    border-color: var(--category-color-tertiary);
    color: var(--category-color-tertiary);
  }

  &--extracurricular {
    border-color: var(--category-color-quaternary);
    color: var(--category-color-quaternary);
  }

  &--noborder {
    background-color: rgb(var(--gray-step-400));
    border: 0;
  }
}
