.new-activity {
  &__input {
    max-width: 30rem;
  }

  &__input-short {
    max-width: 21.2rem;
  }

  &__input-veryshort {
    max-width: 18.5rem;
  }

  &__input-extrashort {
    max-width: 10.5rem;
  }

  &__grid {
    --ion-grid-column-padding: 1.5rem;
    --ion-grid-column-padding-xl: 2rem;
  }

  &__grid-image {
    --ion-grid-column-padding: 2rem;
  }

  &__grid-development {
    --ion-grid-column-padding: 0;
  }

  &__grid-location {
    margin: 0 0 5rem;
    max-width: 80.3rem;
  }

  &__price-col {
    padding: 0;

    @include tablet-landscape {
      border-right: 1px solid rgb(var(--gray-step-500));
      padding: 0 1rem 0 0;
    }
  }

  &__modifier-col {
    padding: 0;

    @include tablet-landscape {
      padding: 0 0 0 1rem;
    }
  }

  &__other-location {
    padding-top: 0;

    @include tablet-landscape {
      align-self: flex-start;
      padding-top: 1.5rem;
    }
  }

  &__development {
    padding-bottom: 0;
    padding-left: 0;

    @include tablet-landscape {
      padding-left: 2rem;
    }
  }

  &__development-row {
    column-gap: 0;
    justify-content: space-between;

    @include tablet-landscape {
      column-gap: 4rem;
      justify-content: flex-start;
    }
  }

  &__list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__list-text {
    align-items: center;
    color: rgb(var(--gray-step-800));
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    width: 100%;

    &::before {
      color: var(--ion-color-primary);
      margin-right: 1rem;
    }

    &--equipment::before {
      content: '\e122';
      font: var(--fa-font-solid);
    }

    &--professor::before {
      content: '\f508';
      font: var(--fa-font-light);
    }

    &--modifier::before {
      content: '\f02b';
      font: var(--fa-font-light);
    }

    &--refund::before {
      content: '\f4c0';
      font: var(--fa-font-light);
    }
  }

  &__delete {
    color: red;
    cursor: pointer;
    margin-right: 1rem;
  }

  &__list-desc {
    width: 100%;
    word-break: break-word;
  }

  &__title {
    display: block;
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);

    @include mobile-landscape {
      font-size: var(--font-size-lg);
    }
  }

  &__prices-grid {
    --ion-grid-columns: 18;
    --ion-grid-padding: 0;
  }

  &__prices-row {
    row-gap: 3rem;
  }

  &__disclaimer {
    --font-size: var(--font-size-xs);

    color: rgb(var(--gray-step-800));
    display: block;
    font-weight: var(--font-regular);

    @include mobile-landscape {
      --font-size: var(--font-size-base);
    }
  }

  &__refund {
    --font-size: var(--font-size-xs);

    color: rgb(var(--gray-step-1000));
    display: block;
    font-weight: var(--font-regular);

    @include mobile-landscape {
      --font-size: var(--font-size-base);
    }
  }

  &__end-buttons {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__end-buttons-container {
    column-gap: 3rem;
    display: flex;
    justify-content: center;
    max-width: 70rem;
    width: 100%;
  }

  &__refund-input {
    max-width: 5rem;
  }

  &__refund-container {
    min-height: 30rem;

    @include mobile-landscape {
      min-height: 25rem;
    }
  }

  &__image-messages {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__promote-popover {
    --min-width: 30rem;
  }

  &__location-alert {
    color: rgb(var(--red));
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);

    & i {
      font-size: var(--font-size-md);
    }
  }

  &__pricemodifier-title {
    margin-bottom: 1.1rem;
  }

  &__pricemodifier-subtitle {
    color: rgb(var(--gray-step-900));
    display: block;
    -webkit-line-clamp: 2;
    min-height: 3.8rem;
  }
}
