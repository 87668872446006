.age-selector {
  column-gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 51rem;
  position: relative;
  row-gap: 3rem;

  &__label {
    background: rgb(var(--gray-step-400));
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    font-size: var(--font-size-md);
    font-weight: var(--font-regular);
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    transition: background var(--normal) ease-in-out;
    width: 6rem;
  }

  &__checkbox {
    left: -9999rem;
    position: absolute;
    visibility: hidden;

    &:checked + .age-selector__label {
      background: rgb(var(--gray-step-1000));
      color: rgb(var(--gray-step-400));
    }
  }

  @include mobile-landscape {
    column-gap: 3rem;
    row-gap: 3rem;
  }
}
