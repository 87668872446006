.modal-presignup {
  padding: 0 4rem 3rem;

  @include mobile-landscape {
    padding-bottom: 6.6rem;
  }
}

.pre-signup {
  display: flex;
  flex-flow: column;
  row-gap: 2rem;

  &__description {
    column-gap: 3rem;
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);
    justify-content: space-between;
    text-align: left;

    @include mobile-landscape {
      column-gap: 7rem;
      font-size: var(--font-size-md);
    }
  }

  &__block {
    background: rgb(var(--gray-step-250));
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    row-gap: 2rem;
  }

  &__image {
    width: 5rem;

    @include mobile-landscape {
      width: 9.2rem;
    }
  }
}
