.language-switcher {
  align-items: center;
  display: flex;
  font-size: var(--font-size-xs);
  text-transform: uppercase;

  &__language {
    --padding-start: 0.5rem !important;
    --padding-end: 0.5rem !important;

    font-size: var(--font-size-xs);

    &--active {
      color: rgb(var(--gray-step-1050));
      pointer-events: none;
    }
  }
}
