.paginator {
  &__pagination {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  &__pagination-title {
    font-size: var(--font-size-base);
    font-weight: var(--font-medium);

    @include tablet-landscape {
      font-size: var(--font-size-md);
    }
  }

  &__pages {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    max-width: 20rem;

    @include mobile-landscape {
      max-width: 50rem;
    }
  }

  &__extreme-pages {
    align-items: center;
    display: flex;
  }
}
