.reserves-stats {
  --bg-reserves: rgba(104, 63, 143, 30%);
  --bg-incoming: rgba(65, 185, 192, 30%);

  column-gap: 0.3rem;
  display: grid;
  grid-template-columns: 5rem 1fr 1fr;
  max-width: 54.3rem;
  min-width: 37rem;
  row-gap: 0.3rem;
  text-align: center;
  width: 100%;

  &__cell {
    height: 5.2rem;
    line-height: 5.2rem;
  }

  &__header {
    font-size: var(--font-size-xs);
    font-weight: var(--font-semibold);

    @include tablet-landscape {
      font-size: var(--font-size-base);
    }
  }

  &__month {
    color: rgb(var(--gray-step-800));
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-bold);

    @include tablet-landscape {
      font-size: var(--font-size-xs);
      font-weight: var(--font-regular);
    }
  }

  &__stat {
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-regular);

    &--reserves {
      background-color: var(--bg-reserves);
    }

    &--incoming {
      background-color: var(--bg-incoming);
    }

    @include tablet-landscape {
      font-size: var(--font-size-base);
    }
  }
}
