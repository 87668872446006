.m-activities {
  position: relative;

  &__select {
    max-width: 28.1rem !important;
    width: 0;
  }

  &__categories {
    background: #f5f5f5;
    border-radius: 1rem;
    column-gap: 2rem;
    display: flex;
    flex-wrap: nowrap;
    padding: 2.5rem 1.3rem;
    position: absolute;
    z-index: var(--undertop);
  }

  &__category {
    border-radius: 1rem;
    color: rgb(var(--gray));
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 7.7rem;
    place-content: center;
    place-items: center;
    row-gap: 1rem;
    width: 15.3rem;
  }

  &__category-icon {
    fill: rgb(var(--gray));
    height: 3.2rem;
    width: 3.2rem;
  }

  &__category-title {
    color: rgb(var(--gray));
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-semibold);
    white-space: nowrap;
  }
}
