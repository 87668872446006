.toolbar {
  --min-height: 6rem;
  --ion-toolbar-color: rgb(var(--ion-color-primary));

  max-width: 100vw;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  ion-button {
    height: auto;
  }

  &__username {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);
    white-space: nowrap;
  }

  &__logo {
    background: url('/assets/img/logo_becubs.svg') no-repeat;
    height: 3.2rem;
    width: 16rem;

    &--academy {
      background: url('/assets/img/logo_becubs_for_businessv8.png') no-repeat;

      @include mobile-landscape {
        background: url('/assets/img/logo_becubs_for_business_desktop.svg')
          no-repeat;
        height: 4rem !important;
        width: 30rem !important;
      }
    }

    @include mobile-landscape {
      height: 4rem;
      width: 20rem;
    }
  }

  &__logo-button {
    --padding-start: 0 !important;
    --padding-end: 0 !important;

    margin: 0 !important;
    margin-inline: 0 !important;
  }

  &__start {
    margin-left: 1rem;

    @include mobile-landscape {
      margin-left: 5rem;
    }

    @include tablet {
      margin-left: 10rem;
    }

    @include tablet-landscape {
      margin-left: 10.6rem;
    }
  }

  &__end {
    margin-right: 1rem;

    ion-row {
      column-gap: 1rem;
      flex-wrap: nowrap;

      @include mobile-sm {
        column-gap: 0;
      }
    }

    @include mobile-landscape {
      margin-right: 5rem;
    }

    @include tablet {
      margin-right: 10rem;
    }

    @include tablet-landscape {
      margin-right: 10.6rem;
    }
  }

  &__menu-button-icon {
    font-size: var(--font-size-xxxxl);

    i {
      line-height: normal;
    }

    @include mobile-landscape {
      font-size: var(--font-size-heavy);
    }
  }

  &__categories {
    display: flex;
    justify-content: space-between;
    max-width: 70rem;
    place-items: center;
    width: 100%;
  }

  &__categories-container {
    flex-grow: 1;

    &--mobile {
      display: none;

      @media only screen and (max-width: 1400px) {
        display: block;
      }
    }

    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }

  &__categories-button {
    --toolbar-border-radius: 6rem !important;
    --toolbar-padding-top: 1.3rem !important;
    --toolbar-padding-bottom: 1.3rem !important;
    --toolbar-padding-start: 2.7rem !important;
    --toolbar-padding-end: 2.7rem !important;

    min-width: 15.5rem;
  }

  &__categories-switch {
    --toolbar-padding-top: 0.5rem !important;
    --toolbar-padding-bottom: 0.5rem !important;
    --toolbar-padding-start: 0.5rem !important;
    --toolbar-padding-end: 0.5rem !important;

    font-size: var(--font-size-xxs) !important;
    min-width: auto;

    &::part(native) {
      color: rgb(var(--gray)) !important;
    }

    @include mobile-landscape {
      --toolbar-padding-start: 2rem !important;
      --toolbar-padding-end: 2rem !important;
      --toolbar-padding-top: 1.3rem !important;
      --toolbar-padding-bottom: 1.3rem !important;

      font-size: var(--font-size-xs);
    }
  }

  &__categories-switch-icon {
    font-size: var(--font-size-lg);
    position: relative;
    top: 2px;
  }

  &__menu {
    padding-right: 0;

    @include mobile-landscape {
      padding-right: 2rem;
    }
  }

  @include mobile-landscape {
    --min-height: 9rem;
  }
}
