.faqs {
  background-color: rgb(var(--gray-step-150));
  padding: 10rem 0;
  position: relative;
  width: 100%;

  &__background {
    left: 50%;
    max-width: initial;
    position: absolute;
    top: 10rem;
    transform: translateX(-50%);

    @include tablet-landscape {
      top: -10rem;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 142rem;
    padding: 0 1rem;
    position: relative;
    z-index: 1;
  }

  &__header-title {
    color: var(--gray-step-1000, #1f1f1f);
    font-size: var(--font-size-xxl, 2.5rem);
    font-weight: 800;
    margin-bottom: 4.3rem;
  }

  &__header-subtitle {
    font-size: var(--font-size-big, 4rem);
    font-weight: 800;
    margin-bottom: 3.2rem;
  }

  &__cards {
    align-items: center;
    column-gap: 15rem;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10.3rem;
    row-gap: 2rem;

    @include tablet {
      align-items: flex-start;
    }
  }

  &__card {
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 26rem;
    justify-content: flex-start;
    max-width: 100%;
    padding: 2.5rem;
    transition: all var(--normal);
    width: 45rem;

    &--active,
    &:hover {
      backdrop-filter: blur(0.6rem);
      background: rgba(255, 255, 255, 25%);
      gap: 0.5rem;
    }
  }

  &__card-image {
    align-items: flex-end;
    display: flex;
    height: 14rem;
    justify-content: center;
    width: 14rem;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__card-title,
  &__card-description {
    color: rgb(var(--dark-blue));
    font-weight: 800;
    margin: 0;
    text-align: center;
  }

  &__card-title {
    font-size: var(--font-size-lg, 2rem);
  }

  &__card-description {
    font-size: var(--font-size-base, 1.6rem);
  }

  &__accordion-title {
    color: rgb(var(--dark-blue));
    font-size: var(--font-size-llg, 2.2rem);
    font-weight: 800;
    margin-bottom: 2.7rem;
  }

  &__accordion {
    margin-bottom: 10rem;
  }
}
