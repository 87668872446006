@mixin mobile-sm {
  @media (max-width: 389px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1024px) {
    @content;
  }
}
