.menu-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @include mobile-landscape {
    margin-bottom: 5rem;
    place-content: center;
  }
}

.links {
  color: var(--ion-color-primary);
  margin-bottom: 2rem;

  &__title {
    font-size: var(--font-size-llg);
    font-weight: var(--font-semibold);

    @include mobile-landscape {
      font-size: var(--font-size-xxl);
      margin-bottom: 3rem;
    }
  }

  &__link {
    background-color: var(--transparent, rgba(0, 0, 0, 0%));
    color: inherit;
    font-size: var(--font-size-md);
    font-weight: var(--font-medium);
    text-decoration: none;

    @include mobile-landscape {
      font-size: var(--font-size-llg);
    }
  }
}
