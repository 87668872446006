.places-chart {
  --reserves-width: 0%;
  --available-width: 0%;

  display: flex;
  height: 5.4rem;
  width: 100%;

  &__segment {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;

    &--reserved {
      background: #00aba9;
      width: var(--reserves-width);
    }

    &--available {
      background: #006f16;
      width: var(--available-width);
    }
  }

  &__value {
    color: rgb(var(--gray));
    font-size: var(--font-size-base);
    font-weight: var(--font-bold);
    overflow: hidden;
    padding: 1rem;
    text-overflow: ellipsis;
  }
}
