.contact {
  background-color: rgb(var(--gray-step-150));
  padding: 10rem 2rem;
  position: relative;
  width: 100%;

  &__background {
    left: 50%;
    max-width: initial;
    position: absolute;
    top: 10rem;
    transform: translateX(-50%) rotate(90deg);

    @include tablet {
      transform: translateX(-50%) translateY(-10%);
    }

    @include tablet-landscape {
      top: -10rem;
      transform: translateX(-50%);
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 160rem;
    position: relative;
    z-index: 1;
  }

  &__header-title {
    color: var(--gray-step-1000, #1f1f1f);
    font-size: var(--font-size-xxl, 2.5rem);
    font-weight: 800;
    margin-bottom: 4.3rem;

    &--center {
      text-align: center;
    }
  }

  &__press-link {
    pointer-events: none;
  }

  &__header-subtitle {
    font-size: var(--font-size-big, 4rem);
    font-weight: 800;
    margin-bottom: 3.2rem;
  }

  &__cards {
    align-items: center;
    column-gap: 5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 10.3rem;
    row-gap: 2rem;

    @include tablet {
      align-items: flex-start;
      column-gap: 0.5rem;
    }

    @include laptop {
      column-gap: 3rem;
      flex-flow: row nowrap;
    }
  }

  &__card {
    align-items: center;
    backdrop-filter: blur(0.6rem);
    background: rgba(255, 255, 255, 25%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 26rem;
    justify-content: flex-start;
    justify-content: center;
    max-width: 100%;
    padding: 1rem 4rem;
    transition: all var(--normal);
    width: 36rem;
  }

  &__card-image {
    align-items: flex-end;
    display: flex;
    height: 8rem;
    justify-content: center;
    margin-bottom: 2rem;
    width: 14rem;

    &--wider {
      width: 17rem;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__card-title {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-lg, 2rem);
    font-weight: 800;
    margin-bottom: 0.5rem;
    margin-top: 0;
    text-align: center;
  }

  &__card-description {
    font-size: var(--font-size-xxxxs, 1.1rem);
    font-weight: var(--font-medium);
    margin: 0;
    text-align: center;

    @include tablet {
      font-size: var(--font-size-xs, 1.4rem);
      margin: 0;
    }
  }

  &__accordion-title {
    color: rgb(var(--dark-blue));
    font-size: var(--font-size-llg, 2.2rem);
    font-weight: 800;
    margin-bottom: 2.7rem;
  }

  &__card-divider {
    border: 0.1rem solid rgb(var(--gray-step-1050));
    color: rgb(var(--gray-step-1050));
    height: 0;
    width: 100%;
  }
}
