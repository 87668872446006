.cookies-banner {
  bottom: 8rem;
  margin: 0 auto;
  max-width: 165rem;
  padding: 0 1rem;
  position: sticky;
  z-index: var(--overtop);

  .cc-window {
    position: absolute !important;
  }

  @include tablet {
    bottom: 0;
  }
}
