.swiper {
  &__bullet {
    background: rgba(var(--gray-step-800), 0.388);
    border-radius: 100%;
    display: inline-block;
    height: 1.2rem;
    margin-right: 0.9rem;
    width: 1.2rem;

    &--active {
      background: rgb(var(--gray-step-800));
      border: 0.1rem solid rgb(var(--gray-step-900));
    }
  }
}
