.favorites {
  display: grid;
  grid-template-columns: 100%;

  &__bg {
    background: rgb(var(--gray-step-150)) url('/assets/img/bg_mobile.webp')
      no-repeat;
    background-size: cover;

    @include tablet {
      background: rgb(var(--gray-step-150)) url('/assets/img/bg_desktop.png')
        no-repeat;
      background-size: cover;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    height: 10rem;
    justify-content: center;
    min-width: 22.5rem;
    padding: 0;
    place-content: center;

    @include tablet {
      justify-content: flex-end;
      padding: 0 0 2rem;
    }
  }

  &__header-content {
    align-items: center;
    //background-color: rgba(var(--gray), 41%);
    border-radius: 2rem;
    column-gap: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    row-gap: 0;

    @include tablet {
      flex-direction: row;
      padding: 0.3rem 1rem;
      row-gap: 1rem;
    }
  }

  &__title {
    font-size: var(--font-size-xxxxl);
    font-weight: var(--font-semibold);
  }

  &__title-heart {
    color: rgb(var(--red));
    font-size: var(--font-size-lg);
  }

  &__title-count {
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    font-weight: var(--font-medium);
  }

  &__title-saved {
    color: rgb(var(--gray-step-900));
    font-weight: var(--font-semibold);
  }
}
