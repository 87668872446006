.participants-modal {
  background: url('/assets/img/cab_login.png') 0% 0% no-repeat padding-box;
  background-size: 100% 15rem;

  &__header {
    background-color: var(--transparent) !important;
    color: var(--ion-color-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-semibold) !important;
  }
}
