*,
*::before,
*::after {
  box-sizing: border-box;
}

// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// b,
// u,
// i,
// center,
// dl,
// dt,
// dd,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
// article,
// aside,
// canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   vertical-align: baseline;
// }
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  background-color: rgb(var(--gray));
  color: rgb(var(--gray-900));
  font-family: var(--ion-font-family);
  font-size: var(--font-size-sm);
  font-weight: var(--font-regular);
  height: min-content;
  max-height: none;
  overflow-x: hidden;

  // Estilos para poder hacer scroll sin ion-content
  overflow-y: auto;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
