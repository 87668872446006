.reasons {
  background: rgb(var(--gray-step-150))
    url('/assets/img/bg_razones_mobile.webp') no-repeat;
  background-size: 100% 100%;
  width: 100%;

  &__container {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100vw;
  }

  &__title {
    color: var(--ion-color-primary);
    display: inline-block;
    font-size: var(--font-size-xxl);
    font-weight: var(--font-extrabold);
    line-height: 3.6rem;
    max-width: 34rem;

    @include mobile-landscape {
      max-width: inherit;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    padding-top: 5rem;
  }

  &__image {
    height: 40rem;
    overflow: visible;
    width: 38rem;
  }

  &__phone {
    transform: scale(1.7) translate(8px, 31px);

    @include mobile-landscape {
      transform: scale(1.3) translate(3px, -20px);
    }

    @include tablet {
      transform: scale(1) translate(3px, -85px);
    }

    @include tablet-landscape {
      transform: scale(1.5);
    }
  }

  &__list {
    color: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include tablet-landscape {
      column-gap: 1rem;
      flex-direction: row;
    }
  }

  &__item {
    align-items: center;
    column-gap: 2rem;
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);
    padding-left: 2rem;
    padding-right: 2rem;

    @include tablet-landscape {
      align-items: center;
      column-gap: 2rem;
      flex-direction: column;
      font-size: var(--font-size-mmd);
      font-weight: var(--font-semibold);
      max-width: 16rem;
      padding: 0;
      row-gap: 2rem;
      text-align: center;
    }
  }

  &__icon {
    fill: var(--ion-color-primary);
    flex: 0 0 3.5rem;
    width: 3.5rem;

    @include tablet-landscape {
      flex: 0 0 6.5rem;
      height: 6.5rem;
      width: 6.5rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 2.3rem;
  }

  &__title-desktop {
    color: var(--ion-color-primary);
    font-size: var(--font-size-extra);
    font-weight: var(--font-extrabold);
    max-width: 55.5rem;
  }

  &__subtitle {
    color: var(--ion-color-primary);
    font-size: var(--font-size-xxl);
    font-weight: var(--font-medium);
  }

  &__description {
    color: rgb(var(--gray-step-950));
    font-size: var(--font-size-mmd);
    font-weight: var(--font-medium);
    max-width: 75rem;
  }

  &__button {
    padding-top: 4rem;
    width: 21.8rem;

    &--compact {
      margin: 0 auto;
      padding: 0;
    }
  }

  @include tablet-landscape {
    background: rgb(var(--gray-step-150))
      url('/assets/img/bg_razones_desktop.webp') no-repeat;
    background-size: 100% 100%;
  }
}
