.academy-stats {
  &__text {
    display: block;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    line-height: 2rem;
    margin-bottom: 5rem;
    max-width: 88.2rem;
  }

  &__title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-medium);
    margin-bottom: 3.5rem;
  }

  &__title-icon {
    font-size: var(--font-size-xxl);
    margin-right: 1.8rem;
    vertical-align: middle;
  }

  &__profitreserves {
    align-items: center;
    column-gap: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12.5rem;
    row-gap: 7rem;

    @include tablet-landscape {
      align-items: initial;
      flex-direction: row;
    }
  }

  &__profitreserves-chart {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    max-width: 80rem;
    min-height: 22rem;
    min-width: 37rem;
    position: relative;
    width: 100%;

    @include tablet-landscape {
      justify-content: initial;
    }
  }

  &__profitreserves-table {
    max-width: 54.3rem;
    min-width: 37rem;
    position: relative;
    width: 100%;
  }

  &__client-chart {
    display: flex;
    justify-content: center;
    margin-bottom: 12.5rem;
    max-width: 80rem;
    min-height: 25rem;
    position: relative;
    width: 100%;

    @include tablet-landscape {
      justify-content: initial;
    }
  }

  &__places-grid {
    align-items: center;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 100%;
    position: relative;

    @include tablet {
      grid-template-columns:
        minmax(15rem, 20rem)
        minmax(15rem, 20rem)
        13rem
        minmax(15rem, 50rem)
        auto;
    }
  }

  &__places-cell {
    color: #a3a3a3;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    text-transform: capitalize;

    &--session {
      color: #989898;
      display: flex;
      flex-direction: column;
      font-weight: var(--font-bold);
      white-space: nowrap;

      & span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--date-range {
      position: relative;

      &::before {
        color: var(--ion-color-primary);
        content: '\5b';
        display: flex;
        flex-direction: column;
        font: var(--fa-font-regular);
        font-size: 2.6rem;
        height: 100%;
        left: -1.2rem;
        opacity: 0.8;
        place-content: center;
        position: absolute;
      }
    }

    &--options {
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 2rem;

      @include tablet-landscape {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
