.app {
  position: relative;

  &__header {
    border-bottom: 0;
    contain: none;
    height: auto;
    position: sticky;
    top: 0;
    width: 100%;
  }

  &__toolbar {
    flex-grow: 1;
  }

  &__content {
    display: inline;
    min-height: 100vh;
    position: static;
  }

  &__tabs-container {
    bottom: 0;
    height: 6.8rem;
    position: sticky;
    width: 100%;
  }

  /* 
  &__tab {
    padding: 1.5rem 1rem;
  } 
  */

  &__tab-icon {
    font-size: var(--font-size-xxl);
    line-height: normal !important;
    margin-bottom: 0.7rem;
  }

  &__tab-label {
    font-size: var(--font-size-xxxs);
    white-space: nowrap;
  }
}
