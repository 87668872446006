.blog {
  background-image: url('/assets/img/bg_blog-home.png');
  padding-bottom: 5rem;

  &__title {
    align-items: center;
    color: var(--ion-color-primary);
    display: flex;
    font-size: var(--font-size-xxl);
    font-weight: var(--font-extrabold);
    justify-content: space-between;
    margin-bottom: 5.5rem;
    padding: 0 2rem;

    @include mobile-landscape {
      justify-content: center;
      margin-bottom: 6rem;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
  }

  &__card {
    cursor: pointer;
    min-width: 30rem;
  }

  &__image {
    height: 17.7rem;
  }

  &__body {
    padding: 2.5rem 2rem 3.5rem;
  }

  &__title-blog {
    color: var(--ion-color-primary);
    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);
    -webkit-line-clamp: 1;
    line-height: 2rem;
    margin-bottom: 1.3rem;
  }

  &__description {
    color: var(--gray-1050);
    font-size: var(--font-size-xs);
    font-weight: var(--font-regular);
    -webkit-line-clamp: 6;
    line-height: 1.7rem;
    max-height: 11.5rem;
  }

  &__button-container {
    display: flex;
    justify-content: center;

    .button {
      width: 21.8rem;
    }
  }

  &__go-blog-button {
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-semibold);
  }

  @include mobile-landscape {
    padding-bottom: 12.5rem;
  }
}
