.button {
  --toolbar-border-radius: 6rem;
  --toolbar-padding-top: 0.6rem;
  --toolbar-padding-bottom: 0.6rem;
  --toolbar-padding-start: 2rem;
  --toolbar-padding-end: 2rem;
  --border-radius: var(--toolbar-border-radius);
  --padding-top: var(--toolbar-padding-top);
  --padding-bottom: var(--toolbar-padding-bottom);
  --padding-start: var(--toolbar-padding-start);
  --padding-end: var(--toolbar-padding-end);

  margin: 0;

  &--no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    padding: 0;
  }

  &--back {
    --border-color: rgb(var(--gray-step-700));
    --padding-top: 0.8rem;
    --padding-bottom: 0.7rem;
    --padding-start: 1rem;
    --padding-end: 1rem;

    font-size: var(--font-size-xs);
    height: auto;

    & i {
      font-size: var(--font-size-xxxs);
      margin-right: 0.5rem;
    }
  }

  &--toolbar.in-buttons {
    --border-radius: var(--toolbar-border-radius) !important;
    --padding-top: var(--toolbar-padding-top) !important;
    --padding-bottom: var(--toolbar-padding-bottom) !important;
    --padding-start: var(--toolbar-padding-start) !important;
    --padding-end: var(--toolbar-padding-end) !important;
    --background-activated: var(--ion-color-primary);

    font-size: var(--font-size-sm);
    font-weight: var(--font-semibold);
  }

  &--md {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-end: 2.3rem;
    --padding-start: 2.3rem;

    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);
  }

  &--normal {
    --background-hover: rgb(var(--gray));
    --background-focus: rgb(var(--gray));
    --background-activated: rgb(var(--gray));
    --color-activated: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --border-width: 0.2rem;
    --border-style: solid;
  }

  &--in-admin-title {
    --padding-top: 1.2rem;
    --padding-bottom: 1.2rem;
    --padding-start: 1.5rem;
    --padding-end: 1.5rem;

    font-size: var(--font-size-sm);
    font-weight: var(--font-semibold);
  }

  &--login {
    --padding-top: 1.5rem;
    --padding-bottom: 1.5rem;

    width: 21.8rem;
  }

  &--circle {
    --padding-top: 2.3rem;
    --padding-bottom: 2.3rem;
    --padding-left: 2.3rem;
    --padding-right: 2.3rem;
    --border-radius: 6rem;

    font-size: var(--font-size-llg);
    height: 5rem;
    width: 5rem;

    &--big {
      height: 6rem;
      width: 6rem;
    }

    &--icon {
      --padding-top: 1.3rem;
      --padding-bottom: 1.3rem;
      --padding-left: 1.3rem;
      --padding-right: 1.3rem;
      --padding-start: 0;
      --padding-end: 0;

      font-size: var(--font-size-sm);
      height: 3.5rem;
      width: 3.5rem;
    }

    &--sm {
      --padding-top: 1.3rem;
      --padding-bottom: 1.3rem;
      --padding-left: 1.3rem;
      --padding-right: 1.3rem;

      font-size: var(--font-size-sm);
      height: 3.5rem;
      width: 3.5rem;
    }
  }

  &--no-bg {
    --background: var(--transparent);
    --background-hover: var(--transparent);
    --background-focus: var(--transparent);
    --background-activated: var(--transparent);
    --color-hover: var(--input-border-color);
    --color-focus: var(--input-border-color);
    --color: var(--input-border-color);
  }

  &--big {
    --padding-start: 4.6rem;
    --padding-end: 4.6rem;
    --padding-top: 1.3rem;
    --padding-bottom: 1.3rem;

    font-size: var(--font-size-md) !important;
    font-weight: var(--font-semibold);
  }

  &--outline {
    --background: rgb(var(--gray));
    --font-size: var(--font-size-sm);
    --font-weight: var(--font-semibold);
    // eslint-disable-custom-property-no-missing-var-function
    --color: var(--ion-color-primary);
    --border-width: 0.2rem;
  }

  &--active {
    --background: var(--background-activated);
    --color: rgb(var(--gray)) !important;

    &::part(native) {
      background: var(--background-activated) !important;
      border-color: var(--background-activated) !important;
    }
  }

  &--plansoftheday {
    --color: var(--category-color-primary);
    --background-activated: var(--category-color-primary) !important;
  }

  &--camps {
    --color: var(--category-color-secondary);
    --background-activated: var(--category-color-secondary) !important;
  }

  &--birthdays {
    --color: var(--category-color-tertiary);
    --background-activated: var(--category-color-tertiary) !important;
  }

  &--extracurricular {
    --color: var(--category-color-quaternary);
    --background-activated: var(--category-color-quaternary) !important;
  }

  &--close-big,
  &--close {
    --padding-start: 0;
    --padding-end: 0;

    color: var(--ion-color-primary);
    cursor: pointer;
  }

  &--close {
    font-size: var(--font-size-big);
    height: 4rem;
    width: 4rem;
  }

  &--close-big {
    font-size: var(--font-size-big);

    @include mobile-landscape {
      font-size: var(--font-size-giant);
    }
  }

  &__icon {
    font-size: var(--font-size-sm);
    margin-left: 1.5rem;

    &--md {
      font-size: var(--font-size-xxl);
      margin-left: 2.2rem;
    }

    &--big {
      font-size: var(--font-size-xxl);
      margin-left: 2.5rem;
    }

    @include mobile-landscape {
      margin-left: 2.5rem;
    }

    @include mobile-sm {
      margin-left: 0.5rem;
    }
  }

  &--full-width {
    width: 100%;
  }
}

.animated-button {
  align-items: center;
  background-color: rgb(var(--dark-blue, 0 0 255));
  border: solid 0.2rem rgb(var(--dark-blue, 0 0 255));
  border-radius: 99rem;
  color: #fff;
  display: grid;
  font-size: var(--font-size-base, 1.6rem);
  grid-template-columns: 1fr 6rem;
  height: 5rem;
  padding: 0.5rem 1rem;
  width: 23rem;

  &__text {
    text-align: right;
  }

  &__icon {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;

    i {
      transition: transform var(--normal) ease-out;
    }
  }

  &--big {
    grid-template-columns: 1fr 7rem;
    height: 6.65rem;
    width: 30.3rem;
  }

  &:hover {
    background-color: #fff;
    color: rgb(var(--dark-blue, 0 0 255));

    .animated-button__icon i {
      transform: translateX(2.5rem);
    }
  }
}

.button-badge {
  position: relative;

  &__badge {
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;
    --ion-color-base: rgb(var(--color-skyblue)) !important;

    border-radius: 100%;
    bottom: 0;
    font-size: var(--font-size-ultrasmall);
    font-weight: var(--font-bold);
    min-width: 2rem;
    pointer-events: none;
    position: absolute;
    right: -0.7rem;
  }
}
