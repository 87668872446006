.academy-data {
  background: rgba(var(--red), 10%);
  border: 1px dashed rgba(var(--required), 30%);
  border-radius: 2rem;
  display: grid;
  grid-template-areas:
    'icon title'
    'icon subtitle'
    '. general'
    '. other'
    '. responsible';
  grid-template-columns: 5rem 1fr;
  margin: 0 0 1rem;
  padding: 1rem 0;

  &--flex {
    display: flex;
    flex-direction: row;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    &--justified-center {
      justify-content: center;
    }
  }

  &__icon {
    padding: 1.5rem;
  }

  &__cell {
    color: var(--ion-color-primary);
    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);

    &--icon {
      align-self: center;
      color: rgb(var(--required));
      font-size: var(--font-size-xxl);
      grid-area: icon;
      justify-self: center;
    }

    &--title {
      color: rgb(var(--required));
      font-weight: var(--font-medium);
      grid-area: title;
    }

    &--subtitle {
      color: rgb(var(--required));
      font-weight: var(--font-regular);
      grid-area: subtitle;
      margin-bottom: 0.5rem;
    }

    &--general {
      grid-area: general;
    }

    &--other {
      grid-area: other;
    }

    &--responsible {
      grid-area: responsible;
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }
}
