.activity-checkout {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 7.2rem;

  &__container {
    column-gap: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 144rem;
    padding: 0 2rem;
    row-gap: 5rem;
    width: 100%;

    @include tablet-landscape {
      flex-wrap: nowrap;
    }
  }

  &__title-container {
    align-items: end;
    column-gap: 1rem;
    display: flex;
    padding: 2rem 0;
  }

  &__inner {
    height: fit-content;
    width: 100%;

    &--summary {
      margin-bottom: 5rem;
      max-width: 37.2rem;

      @include mobile-sm {
        max-width: 34.2rem;
      }

      @include tablet-landscape {
        max-width: 44.2rem;
        position: sticky;
        top: 26rem;
      }
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    max-width: 140rem;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    position: relative;

    &--border {
      border-top: 1px solid rgba(var(--gray-step-900), 0.17);
    }
  }

  &__subtitle {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-sm);
    margin: 0;
    padding-bottom: 2rem;
  }

  &__title {
    color: rgb(var(--gray-step-1050));
    font-size: var(--font-size-lg);
    margin: 0;

    &--summary {
      margin-top: 2rem;
    }
  }

  &__block-title {
    color: rgb(var(--gray-step-1050));
    padding-bottom: 1rem;
    padding-top: 2rem;

    &--pb0 {
      margin-bottom: 0.7rem;
      padding-bottom: 0;
      padding-top: 1rem;
    }
  }

  &__image {
    align-items: center;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-radius: 1rem;
    display: flex;
    height: 35.4rem;
    place-content: center;
    position: relative;
    width: 100%;

    &--fixed {
      background-image: url('/assets/img/bg_checkout-sm.png');
      background-size: 100% 100% !important;
      height: 100%;

      @include tablet-landscape {
        background-image: url('/assets/img/bg_checkout.png');
      }
    }
  }

  &__summary-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    row-gap: 0;
    width: 100%;

    @include mobile-landscape {
      padding: 2rem 5rem 3.5rem;
      row-gap: 2rem;
    }
  }

  &__breakdown {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  &__breakdown-item {
    border-bottom: 0.2rem solid rgba(var(--gray-step-900), 0.17);
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0 2rem;
  }

  &__summary-total {
    margin-bottom: 1rem;

    @include tablet-landscape {
      margin-bottom: 5rem;
    }
  }

  &__summary-amount {
    display: flex;
    justify-content: space-between;
  }

  &__summary-amount-text {
    color: var(--ion-color-primary);
  }

  &__amount {
    font-weight: var(--font-bold);
  }

  &__academy {
    background: rgb(var(--gray));
    border: 0.1rem solid rgb(var(--gray-step-700));
    border-radius: 9.6rem;
    height: 9.6rem;
    overflow: hidden;
    padding: 0;
    width: 9.6rem;
  }

  &__academy-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100%;
    width: 100%;
  }

  &__description {
    line-height: 3rem;

    &--justify {
      text-align: justify;
    }
  }

  &__items-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    &--extras {
      column-gap: 5rem;
      flex-flow: row wrap;
    }

    &--extras-no-wrap {
      column-gap: 5rem;
      flex-flow: row nowrap;
    }

    &--rgap-lg {
      row-gap: 2.5rem;
    }
  }

  &__options-container {
    column-gap: 1rem;
    display: flex;
    flex-flow: column wrap;
    max-width: 60rem;

    &--schedule {
      align-items: center;
      column-gap: 2rem;
      flex-direction: row;
      margin-left: 1rem;

      @include tablet-landscape {
        flex-direction: row;
        margin-left: 0;
      }
    }

    &--calendar-schedules {
      max-width: 50rem;
    }

    @include tablet-landscape {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__list-text {
    align-items: center;
    color: rgb(var(--gray-step-800));
    display: flex;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    width: 100%;

    &::before {
      color: var(--ion-color-primary);
      margin-right: 1rem;
    }

    &--equipment::before {
      content: '\e122';
      font: var(--fa-font-solid);
    }

    &--professor::before {
      content: '\f508';
      font: var(--fa-font-light);
    }

    &--modifier::before {
      content: '\f02b';
      font: var(--fa-font-light);
    }

    &--refund::before {
      content: '\f4c0';
      font: var(--fa-font-light);
    }

    &--schedule {
      align-items: flex-start;
      color: rgb(var(--gray-step-1050));
      column-gap: 1.5rem;
      flex-direction: column;
      padding-left: 0;

      @include tablet-landscape {
        align-items: center;
        padding-left: 1rem;
      }
    }
  }

  &__min-width {
    min-width: 22rem;
  }

  &__list-text-container {
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  &__delete-item {
    color: red;
    margin-right: 1rem;
  }

  &__list-desc {
    word-break: break-word;
  }

  &__input-less-padding {
    --padding-start: 1.65rem !important;
  }

  &__flex-right {
    display: flex;
    justify-content: right;
  }

  &__refund-container {
    display: flex;
    flex-direction: column;
  }

  &__book-btn {
    --padding-start: 5rem;
    --padding-end: 5rem;

    width: 23rem;

    &--animate {
      @include tablet-landscape {
        i {
          transition: transform 0.5s ease;
        }

        &:hover {
          i {
            transform: translateX(3rem);
          }
        }
      }
    }

    &--cancel {
      --padding-start: 3rem;
      --padding-end: 3rem;

      width: 21.8rem;
    }
  }

  &__attendee-age-alert {
    color: var(--ion-color-primary);
  }

  @include mobile-landscape {
    row-gap: 10rem;
  }
}
