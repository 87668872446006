.home {
  --background: rgb(var(--gray-step-150));

  &__rows {
    row-gap: 4rem;

    @include mobile-landscape {
      row-gap: 12rem;
    }
  }

  &__title {
    margin-bottom: 5rem;
    padding: 0 2rem;
    text-align: center;

    &--subsection {
      text-align: left;

      &--big {
        font-size: var(--font-size-xxxl);
      }
    }

    @include mobile-landscape {
      margin-bottom: 6rem;
    }
  }
}
