.datepicker {
  display: flex;

  &__button {
    flex-grow: 1;
    //max-width: 22rem;

    &--hidden {
      display: none;
    }

    &::part(native) {
      background: rgb(var(--gray));
      border: 0.1rem solid rgb(var(--gray-step-800));
      border-radius: 3.3rem;
      color: rgb(var(--gray-step-1000));
      font-size: var(--font-size-base);
      font-weight: var(--font-regular);
      height: 6.1rem;
      margin: 0;
      width: 100%;

      @include mobile-landscape {
        &::part(native) {
          padding: 2.2rem 2rem 2.2rem 3rem;
        }
      }
    }

    &--required::part(native) {
      border-color: rgb(var(--required));
    }

    &--error::part(native) {
      background: rgb(var(--danger-bg));
      border-color: rgb(var(--danger)) !important;
    }

    &--startend {
      &::part(native) {
        border-radius: 3.3rem 0 0 3.3rem;
      }
    }

    &--end {
      &::part(native) {
        border-radius: 0 3.3rem 3.3rem 0;
      }
    }
  }

  &__button-content {
    align-items: center;
    column-gap: 1.5rem;
    display: flex;

    @include mobile-sm {
      column-gap: 0.5rem;
    }
  }

  &__button-icon {
    font-size: var(--font-size-lg);
  }
}
