.form {
  position: relative;

  &__right-label {
    position: absolute;
    right: 15%;
  }

  &--loading {
    opacity: var(--medium);
    pointer-events: none;
  }

  &__inputmark {
    align-items: center;
    align-self: center;
    color: rgb(var(--orange));
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-xxxs);
    margin-right: 1rem;
    right: 2rem;
    row-gap: 0.4rem;

    i {
      font-size: var(--font-size-sm);
    }
  }

  &__error {
    color: rgb(var(--red));
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-regular);
    left: 2.5rem;
    margin: 0.7rem 0;
    position: absolute;
  }

  &__error-icon {
    font-size: var(--font-size-sm);
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  &__group {
    padding-bottom: 4rem;
    position: relative;

    &--responsive {
      padding-bottom: 2rem;

      @include tablet {
        padding-bottom: 3rem;
      }
    }

    &--small {
      padding-bottom: 1rem;
    }
  }

  &__group-horizontal {
    column-gap: 3rem;
  }

  &__item-multi-input {
    line-height: 2rem;

    &__label {
      display: inline !important;
      font-size: var(--font-size-xs);

      @include mobile-landscape {
        font-size: var(--font-size-base);
      }
    }

    &__input {
      --padding-start: 0.5rem;
      --padding-end: 0.5rem;
      --padding-top: 0;
      --padding-bottom: 0;

      display: inline-block !important;
      font-size: var(--font-size-xs);

      & input {
        text-align: center !important;

        &::placeholder {
          text-align: center;
        }
      }

      @include mobile-landscape {
        font-size: var(--font-size-base);
      }
    }
  }

  &__item {
    --border-radius: 4rem;
    --border-width: 0.1rem;
    --border-color: rgb(var(--gray-step-800));
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --inner-padding-end: 0.5rem;
    --inner-border-width: 0rem;

    border-radius: 4rem;
    font-size: var(--font-size-md);

    &--large {
      max-width: 38.4rem;
    }

    &--short {
      max-width: 21.2rem;
    }

    &--veryshort {
      max-width: 18.5rem;
    }

    &--extrashort {
      max-width: 10.5rem;
    }

    &--disabled {
      opacity: var(--medium);
      pointer-events: none;
    }

    &--required {
      --border-color: rgb(var(--required));
    }

    &--datarequired {
      --border-color: rgb(var(--gray-step-1000)) !important;
      --background: rgba(var(--required), 5%);

      & input {
        background: rgba(var(--required), 0%);
      }
    }

    &--error {
      --border-color: rgb(var(--danger)) !important;
      --background: rgb(var(--danger-bg));
    }

    &--search {
      box-shadow: 0 0.2rem 0.2rem #c4c4c429;
    }

    &--noborder {
      --border-width: 0px;

      width: auto;
    }

    &--border-light {
      --border-color: rgb(var(--gray-step-700));
    }

    &--checkbox {
      --background: var(--transparent);
      --background-activated: none;
      --background-hover: none;
      --border-width: 0px;
      --border-radius: 0;

      border-radius: 0;
    }

    &--checkbox-label {
      column-gap: 0.5rem;
      display: flex !important;
      flex-wrap: wrap;
    }

    &--gray {
      --background: rgb(var(--gray-step-250));
      --border-color: rgb(var(--gray-step-600));
      --placeholder-color: rgb(var(--gray-step-1000));

      color: rgb(var(--gray-step-1000));
    }

    &--radio {
      --border-width: 0;
      --border-radius: 0;
    }
  }

  &__radio-label {
    font-size: var(--font-size-base) !important;
    font-weight: var(--font-regular);
  }

  &__radio {
    height: 2.7rem;
    width: 2.7rem;

    &::part(container) {
      border: 1px solid rgb(var(--gray-step-800));
      border-radius: 0.5rem;
    }

    &::part(mark) {
      background: none;
      border-radius: 0;
      transform: none;
      transition: none;
    }

    &.radio-checked::part(container) {
      background: var(--ion-color-primary);
      border-color: transparent;
    }

    &.radio-checked::part(mark) {
      border-color: #fff;
      border-style: solid;
      border-width: 0 2px 2px 0;
      height: 1.3rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }

  &__input-placeholder {
    color: rgb(var(--gray-step-900));
    font-size: var(--font-size-base);
    padding: 0 1rem 0 0;
  }

  &__input {
    --padding-start: 3.5rem !important;
    --placeholder-opacity: var(--visible);
    --placeholder-color: rgb(var(--gray-step-800));

    &--text-center {
      & input {
        text-align: center !important;

        &::placeholder {
          text-align: center;
        }
      }
    }

    &--text-right {
      & input {
        text-align: right !important;

        &::placeholder {
          text-align: right;
        }
      }
    }

    &--less-padding {
      --padding-start: 1.65rem !important;
      --placeholder-font-size: var(--font-size-sm);

      font-size: var(--font-size-sm);

      @include mobile-landscape {
        --padding-start: 2.2rem !important;
      }
    }

    &--withlabel {
      --padding-start: 2rem !important;
    }

    &--adjusted-native {
      border: 0;
      height: 100%;
      padding: 2rem 2.5rem;
      width: 100%;

      &:focus-visible {
        outline: 0;
      }
    }

    &--search {
      --placeholder-color: var(--ion-color-primary);
      --placeholder-font-weight: var(--font-bold) !important;
      --padding-start: 2rem !important;

      font-size: var(--font-size-xs) !important;

      @include mobile-landscape {
        --padding-start: 2.5rem !important;

        font-size: var(--font-size-lg) !important;
      }
    }

    &--md {
      --padding-top: 2rem;
      --padding-bottom: 2rem;
      --placeholder-font-weight: var(--font-regular);
      --placeholder-font-size: var(--font-size-md);

      font-size: var(--font-size-base);
      font-weight: var(--font-regular);

      @include mobile-landscape {
        font-size: var(--font-size-md);
      }
    }

    &--lg {
      --padding-top: 2.5rem;
      --padding-bottom: 2.5rem;
      --placeholder-font-weight: var(--font-bold);
      --placeholder-font-size: var(--font-size-md-lg);

      font-size: var(--font-size-md-lg);
      font-weight: var(--font-semibold);
    }

    &--special {
      & input {
        max-width: 85% !important;
        padding-left: 2rem !important;
        text-align: right !important;
      }
    }

    &--special-filled {
      & input {
        max-width: 55% !important;
        padding-left: 2rem !important;
        text-align: right !important;
      }
    }
  }

  &__input-label {
    margin: 0 0 0 2.5rem;

    &--gray {
      color: rgb(var(--gray-step-800)) !important;
    }
  }

  &__checkbox {
    --border-radius: 1rem;
    --border-color: rgb(var(--gray-step-800));
    --ion-item-background: rgb(var(--gray-step-450));

    &--error {
      --border-color: rgb(var(--danger));
    }

    &--white {
      --border-radius: 0.5rem;
      --border-color: rgb(var(--gray-step-800));
      --ion-item-background: rgb(var(--gray));

      font-size: var(--font-size-base);
    }

    &--label {
      margin: 0;
    }
  }

  &__select {
    --padding-top: 1.2rem;
    --padding-bottom: 1.2rem;
    --padding-start: 2rem;
    --padding-end: 2rem;
    --placeholder-color: rgb(var(--gray-step-800));
    --placeholder-opacity: var(--visible);

    font-size: var(--font-size-base);

    &--big {
      --padding-top: 2rem;
      --padding-bottom: 2rem;
    }

    &::part(icon) {
      color: var(--transparent);
      font-size: var(--font-size-base);
      opacity: 1;
      position: absolute;
      right: 1.5rem;

      &::before {
        color: rgb(var(--gray-step-1000));
        content: '\f107';
        font: var(--fa-font-regular);
      }

      @include mobile-landscape {
        right: 3rem;
      }
    }

    &--multiline {
      --padding-start: 1.42rem;
      --padding-end: 2.42rem;
      --padding-top: 0.75rem;
      --padding-bottom: 0.75rem;

      font-size: var(--font-size-xxxs);

      &::part(icon) {
        right: 1.5rem;
      }

      &::part(text) {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
      }

      @include mobile-landscape {
        --padding-start: 3rem;
        --padding-end: 3rem;
        --padding-top: 1.2rem;
        --padding-bottom: 1.2rem;

        font-size: var(--font-size-md);

        &::part(text) {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    @include mobile-landscape {
      --padding-start: 3rem;
      --padding-end: 3rem;

      font-size: var(--font-size-md);
    }
  }
}

.input-validator {
  --border-width: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 2rem;
  --inner-border-width: 0rem;

  font-size: var(--font-size-xs);
  font-weight: var(--font-regular);
}

.wider-popover::part(content) {
  max-height: 30rem;
  width: auto;
  z-index: 2;
}

.range {
  --bar-height: 1rem;
  --bar-background: rgb(var(--gray-step-900));
  --knob-background: rgb(var(--gray-step-1000));
  --bar-border-radius: 10rem;
  --knob-size: 3.1rem;

  &::part(pin) {
    top: 5rem;
    transform: none;
  }
}

.popover-multiline {
  &::part(content) {
    width: auto;
  }
}

.action-sheet-multiline {
  .action-sheet-button.sc-ion-action-sheet-ios {
    contain: content;
    height: auto;
  }
}

.select-action-sheet {
  height: 100dvh;
}
