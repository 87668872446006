.tippy-box[data-theme~='usermenu'] {
  background-color: rgb(var(--gray));
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 15%),
    0 4px 80px -8px rgba(36, 40, 47, 25%), 0 4px 4px -2px rgba(91, 94, 105, 15%);
  color: rgb(var(--gray-step-1000));

  & > .tippy-content {
    padding: 0 !important;
  }

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: rgb(var(--gray));
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: rgb(var(--gray));
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: rgb(var(--gray));
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: rgb(var(--gray));
  }

  & > .tippy-backdrop {
    background-color: rgb(var(--gray));
  }

  & > .tippy-svg-arrow {
    fill: rgb(var(--gray));
  }
}

.user-menu {
  display: grid;
  grid-template-columns: 100%;
  padding: 0.5rem;

  &__item {
    border-radius: 0.4rem;
    font-size: var(--font-size-base);
    font-weight: var(--font-regular);
    padding: 1rem 1.5rem;

    & i {
      color: var(--ion-color-primary);
      font-size: var(--font-size-md);
      margin-right: 1.5rem;
      width: 1.6rem;
    }

    &--logout i {
      color: rgb(var(--red));
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgb(var(--gray-step-400));
    }

    &:hover {
      background-color: rgba(var(--dark-blue), 5%);
      color: rgb(var(--gray-step-1050));
      cursor: pointer;
    }
  }
}
