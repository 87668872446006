.modal {
  --modal-padding-horizontal: 3rem;
  --modal-padding-vertical: 3rem;
  --modal-padding-horizontal-sm: 10rem;
  --modal-padding-vertical-sm: 6rem;
  --backdrop-opacity: 0.75;
  --box-shadow: 0px 0px 50px #00000080;
  --border-radius: 0;

  &::part(content) {
    transition: width var(--normal) ease-in-out;
  }

  &--lg {
    --max-width: 140rem !important;
    --width: 100%;
  }

  ion-content {
    --padding-start: var(--modal-padding-horizontal) !important;
    --padding-end: var(--modal-padding-horizontal) !important;
    --padding-top: var(--modal-padding-vertical) !important;
    --padding-bottom: var(--modal-padding-vertical) !important;

    @include mobile-landscape {
      --padding-start: var(--modal-padding-horizontal-sm) !important;
      --padding-end: var(--modal-padding-horizontal-sm) !important;
      --padding-top: var(--modal-padding-vertical-sm) !important;
      --padding-bottom: var(--modal-padding-vertical-sm) !important;
    }
  }

  &.bottom {
    align-items: flex-end;
  }

  .ion-page {
    contain: content;
    display: block;
    overflow: hidden;
    position: relative;

    .inner-content {
      max-height: 100vh;
      overflow: auto;
      padding-bottom: 5rem;

      @include mobile-landscape {
        padding-bottom: 0;
      }
    }
  }

  &--fullscreen {
    --width: 100% !important;
    --height: 100% !important;
    --max-height: 100% !important;
    --max-width: 100% !important;
    --border-radius: 0 !important;
  }

  &--fullscreen,
  &--payment,
  &--user {
    height: 100dvh;
    position: sticky;

    ::part(scroll) {
      height: min(calc(100% - 0.2rem), calc(100dvh - 0.2rem));
    }
  }

  &--menu-bg {
    ::part(background) {
      --background: url('/assets/img/bg_menu-home.png') 0% 0% no-repeat
        padding-box;

      background-size: cover;
    }
  }

  &--bg-login {
    background: url('/assets/img/cab_login.png') 0% 0% no-repeat padding-box;
    background-size: 100% 15rem;
  }

  &--bg-business {
    background: url('/assets/img/cab_login_business.png') 0% 0% no-repeat
      padding-box;
    background-size: 100% 15rem;
  }

  &--bg-onboarding {
    --background: rgb(var(--gray-step-400));
  }

  @include tablet {
    --border-radius: 2rem;
    --height: auto;
    --max-width: 60rem;

    &--payment {
      --max-width: 51rem;

      height: 100dvh;
      position: sticky;

      ::part(scroll) {
        height: min(calc(100% - 0.2rem), calc(100dvh - 0.2rem));
      }
    }
  }
}

.modal-content {
  font-size: var(--font-size-md);
  font-weight: var(--font-regular);
  text-align: center;

  &__header {
    align-items: center;
    border-bottom: 1px solid rgba(var(--gray-step-900), 0.17);
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
    padding: 8rem 8rem 3rem;
    row-gap: 3.5rem;
    text-align: center;

    &--business {
      border-bottom: 0;
    }

    @include mobile-sm {
      padding: 2rem 0;
    }

    @include mobile-landscape {
      margin: 0 10rem;
      padding: 6rem 0 3rem;
    }
  }

  &__header-title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-medium);
  }

  &__title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-xxl);
    font-weight: var(--font-extrabold);
    margin-bottom: 2.5rem;
  }

  &__link {
    color: var(--ion-color-primary);
    cursor: pointer;
    font-size: var(--font-size-base);
    font-weight: var(--font-semibold);
  }

  &__center {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-height: calc(100vh - 6rem);
    width: 100%;

    @include tablet {
      max-height: 80rem;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    text-align: center;
  }

  &__description {
    font-size: var(--font-size-base);
  }
}
