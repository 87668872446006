.admin-menu-small {
  border: 0.1rem solid rgb(var(--gray-step-600));
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 32rem;

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2.5rem;

    &:hover {
      & span {
        color: var(--ion-color-primary);
      }

      & svg {
        fill: var(--ion-color-primary);
      }
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid rgb(var(--gray-step-600));
    }

    &--active {
      & span {
        color: var(--ion-color-primary);
      }

      & svg {
        fill: var(--ion-color-primary);
      }
    }

    &--disabled {
      background-color: rgb(var(--gray-step-200));
      cursor: auto;

      &:hover {
        & span {
          color: rgb(var(--gray-step-800));
        }

        & svg {
          fill: rgb(var(--gray-step-800));
        }
      }
    }

    &--current {
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0%) 0%,
        rgba(var(--green), 20%) 100%
      );
      border: 0.1rem solid rgb(var(--gray-step-900)) !important;
      color: rgb(var(--gray-step-900));
    }
  }

  &__title {
    color: rgb(var(--gray-step-800));
    font-size: 1.8rem;
    font-weight: var(--font-medium);
  }

  &__icon {
    fill: rgb(var(--gray-step-800));
    width: 4rem;
  }
}
