.about-us {
  background-color: rgb(var(--gray-step-150));
  min-height: calc(100vh - 9rem);
  overflow: hidden;

  &__title,
  &__subtitle,
  &__description {
    margin: 0;
    text-align: left;
    white-space: break-spaces;

    &--blue {
      color: rgb(var(--dark-blue));
    }

    &--italic {
      font-style: italic;
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }

    &--mt0 {
      margin-top: 0 !important;
    }

    &--mb0 {
      margin-bottom: 0 !important;
    }
  }

  &__title {
    font-size: var(--font-size-xxl, 2.5rem);
    font-weight: 800;
    margin-bottom: 2rem;

    @include tablet-landscape {
      margin-bottom: 9.4rem;
    }
  }

  &__subtitle {
    color: rgb(var(--dark-blue));
    font-size: var(--font-size-xxl, 2.5rem);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 2.4rem;

    @include tablet {
      font-size: var(--font-size-big, 4rem);
      line-height: 1.25;
      margin-bottom: 4rem;
    }
  }

  &__description {
    font-size: var(--font-size-md, 1.8rem);
    line-height: 1.25;
    margin-bottom: 4.8rem;

    &--big {
      font-size: var(--font-size-xxl, 2.5rem) !important;
      margin-bottom: 3.1rem;
    }

    &--bold {
      font-weight: 600;
    }

    &--bolder {
      font-weight: 800;
    }

    @include tablet {
      font-size: var(--font-size-lg, 2rem);
      line-height: 1.5;
    }
  }
}

.about-us-first-section {
  margin: 0 auto;
  max-width: 125rem;
  z-index: 1;

  .about-us__title,
  .about-us__subtitle,
  .about-us__description {
    &--right {
      text-align: left;

      @include tablet {
        text-align: right;
      }
    }
  }

  &__background {
    left: 0;
    max-height: 100%;
    max-width: initial;
    min-width: 100vw;
    position: absolute;
    top: 0;
    transform: scale(0.95);
    transform-origin: 0 0;

    @include tablet {
      left: 50%;
      transform: translateX(-50%) scale(1);
    }
  }

  &__content {
    padding: 5.4rem 2rem 0 1rem;

    @include tablet-landscape {
      padding: 10rem 3rem 0;
    }
  }

  &__row {
    column-gap: 2rem;
    display: grid;
    grid-template-areas: 'lg' 'sm';
    grid-template-columns: 100%;
    height: auto;
    margin-bottom: 5rem;
    width: 100%;

    &--white {
      .about-us__subtitle,
      .about-us__description {
        color: white;
      }

      .about-us__subtitle {
        font-weight: 600;

        @include tablet {
          font-weight: 800;
        }
      }
    }

    @include tablet-landscape {
      grid-template-areas: 'lg sm';
      grid-template-columns: 1fr 40rem;
      margin-bottom: 11rem;

      &:last-child {
        margin-bottom: 0;
      }

      &--reverse {
        grid-template-areas: 'sm lg';
        grid-template-columns: 35rem 1fr;
      }
    }
  }

  &__col {
    display: inline-block;
    min-height: 0;
    padding: 0;

    &--big {
      grid-area: lg;
    }

    &--small {
      grid-area: sm;
    }
  }

  &__image-wrapper {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;

    @include tablet-landscape {
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &__image {
    object-fit: contain;
    width: min(34.3rem, 100%);

    @include tablet-landscape {
      position: absolute;
      width: 100%;
    }
  }
}

.about-us-second-section {
  margin: 11.6rem auto 0;
  width: 100%;

  &__background {
    height: 42rem;
    left: 50%;
    max-height: 100%;
    max-width: initial;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    transform-origin: 0 50%;

    @include tablet {
      height: auto;
      left: 0;
      max-height: 100%;
      max-width: 100vw;
      top: 50%;
      transform: translateX(-10rem) translateY(-50%) scale(0.8);
    }
  }

  &__content {
    padding: 0 2rem;
    width: 100%;
  }

  &__row {
    align-items: center;
    margin: 0 auto 10rem;
    max-width: 125rem;
    width: 100%;

    @include tablet {
      margin-bottom: 0;
    }
  }

  &__col {
    display: grid;
    grid-template-areas: 'subtitle' 'images' 'description';
    grid-template-columns: 100%;
    width: 100%;

    .about-us__subtitle {
      grid-area: subtitle;
    }

    .about-us__description {
      grid-area: description;
    }

    .about-us-second-section__images {
      grid-area: images;
    }

    @include tablet {
      grid-template-areas: 'subtitle' 'description' 'images';
    }
  }

  &__images {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 5rem;
  }

  &__image-wrapper {
    display: grid;
    place-items: center;
  }

  @include tablet {
    margin: -10rem auto 0;
    padding-top: 25rem;
  }
}

.about-us-third-section {
  margin: 0 auto;
  max-width: 120rem;
  z-index: 1;

  .about-us__subtitle {
    max-width: 65rem;
  }

  &__background {
    max-height: 100%;
    max-width: initial;
    position: absolute;
    right: -15rem;
    top: 50%;
    transform: translateY(calc((50% + 7.5rem) * -1)) scale(0.9);

    @include tablet {
      left: 50%;
      max-height: 100%;
      right: initial;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &__content {
    padding: 0 1rem;

    @include tablet {
      padding: 52.5rem 3rem 0;
    }
  }

  &__row {
    width: 100%;

    &--low-mb {
      margin-bottom: 3rem;

      @include tablet {
        margin-bottom: 2rem;
      }
    }

    &--mb {
      margin-bottom: 35rem;

      @include tablet {
        margin-bottom: 20rem;
      }
    }
  }

  &__col {
    &--right {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }

    &--center {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  @include tablet {
    margin-top: -10rem;
  }
}

.about-us-fourth-section {
  margin: 5rem auto 15rem;
  max-width: 140rem;

  .animated-button {
    width: 25rem;
  }

  .about-us__subtitle {
    margin-bottom: 5rem;
    padding: 0 1.8rem 0 3rem;

    @include tablet {
      padding: 0;
    }
  }

  .about-us__description {
    &--bold {
      font-weight: 600;

      @include tablet {
        font-weight: 800;
      }
    }
  }

  &__background {
    left: 50%;
    max-width: initial;
    min-width: 150vw;
    position: absolute;
    top: 0;
    transform: translateX(-50%) scale(1.15);

    @include tablet {
      transform: translateX(-50%);
    }
  }

  &__content {
    padding: 10rem 1rem 0;

    @include tablet-landscape {
      padding: 10rem 3rem 0;
    }
  }

  &__row {
    align-items: center;
    width: 100%;
  }

  &__col {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__cards {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 5rem;
    // margin-top: 5rem;
    width: 100%;

    @include tablet {
      align-items: flex-start;
      flex-flow: row;
    }
  }

  &__card {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 100%;

    .about-us__description {
      font-size: var(--font-size-base, 1.6rem);

      @include tablet {
        font-size: var(--font-size-md, 1.8rem);
      }
    }

    @include tablet {
      max-width: 33.3rem;
      padding: 0;
      width: 33%;
    }
  }

  &__card-image {
    height: 8rem;
    width: 8rem;

    @include tablet {
      height: auto;
      width: auto;
    }
  }

  &__card-title {
    color: rgb(var(--dark-blue));
    font-size: var(--font-size-md, 1.8rem);
    font-weight: 800;
    text-align: center;
  }
  // overflow: hidden;

  @include tablet {
    margin-top: 7.2rem;
    overflow: initial;
  }
}

.about-us-fifth-section {
  margin: 0 auto 17rem;
  max-width: 84rem;

  .about-us__description {
    font-family: var(--font-family-italic);
    font-size: var(--font-size-xxl, 2.5rem);
    line-height: 1.25;
    margin-bottom: 15rem;

    @include tablet {
      font-size: var(--font-size-xxxl, 3rem);
      line-height: 1;
      margin-bottom: 10rem;
    }
  }

  &__background {
    left: 5%;
    position: absolute;
    top: 0;
    width: 90%;

    @include tablet {
      left: 2.5rem;
      width: 100%;
    }
  }

  &__content {
    padding: 6rem 1.6rem 0 2rem;

    @include tablet {
      padding-top: 12.5rem;
    }
  }

  &__row {
    align-items: center;
    margin-bottom: 5rem;
    width: 100%;
  }

  &__col {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__images {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    gap: 2rem;
    justify-content: space-between;
    margin-top: 5rem;

    @include tablet {
      flex-direction: row;
    }
  }

  &__image-wrapper {
    --size: 22rem;

    display: grid;
    height: var(--size);
    place-items: center;
    width: var(--size);

    @include tablet {
      --size: 22rem;
    }
  }

  @include tablet {
    margin: 25rem auto;
  }
}
