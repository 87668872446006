ion-footer {
  z-index: 9;
}

.footer {
  background: url('/assets/img/footer_mobile.webp') no-repeat;
  background-size: cover;
  width: 100%;

  &__container {
    align-items: center;
    column-gap: 2rem;
    display: flex;
    justify-content: center;
    padding-bottom: 7rem;
    padding-top: 3rem;
    text-align: center;

    @include tablet-landscape {
      justify-content: space-between;
      padding-bottom: 3.5rem;
      text-align: left;
    }
  }

  &__start {
    max-width: 100%;

    @include tablet {
      max-width: 80rem;
    }
  }

  &__logo {
    display: block;
    height: 4rem;
    width: 19.6rem;
  }

  &__title {
    color: rgb(var(--green));
    word-break: break-word;

    @include tablet-landscape {
      color: rgb(var(--gray-step-1050));
    }
  }

  &__text {
    color: rgb(var(--gray-step-1000));
    font-size: var(--font-size-xs);
  }

  &__button-compact {
    --background: rgb(var(--green));
    --padding-top: 2rem;
    --padding-bottom: 2rem;
    --color: rgb(var(--dark-purple));
    --font-size: var(--font-size-md);
    --font-weight: var(--font-semibold);

    max-width: 26rem;
    text-transform: uppercase;
    width: 100%;
  }

  &__button {
    --background: rgb(var(--gray-step-1000));

    max-width: 19.5rem;
  }

  &__icon {
    color: rgb(var(--gray-step-1050));
    font-size: 4rem;
  }

  &__lists {
    column-gap: 10rem;
    display: flex;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__list-link {
    color: rgb(var(--gray-step-1000));
    font-size: var(--font-size-xs);
    font-weight: var(--font-regular);
    text-decoration: none;
    white-space: nowrap;
  }

  &__list-title {
    color: var(--ion-color-primary);
    font-size: var(--font-size-xs);
    font-weight: var(--font-extrabold);
  }

  &__copy {
    font-size: var(--font-size-xs);
    font-weight: var(--font-medium);
    white-space: nowrap;
  }

  @include tablet-landscape {
    background: rgb(var(--gray-step-350));
    width: 100%;
  }
}
