.accordion {
  --transition-duration: var(--slow);

  &__header {
    align-items: center;
    border-bottom: solid 0.2rem rgb(var(--gray-step-550));
    color: rgb(var(--dark-blue));
    cursor: pointer;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto 1fr;
    padding: 1.5rem 1rem;
    width: 100%;

    &:hover {
      background-color: #fff;
    }
  }

  &__header-icon {
    color: inherit;
    font-size: var(--font-size-lg, 2rem);
    font-weight: 600;
    transition: transform var(--transition-duration) ease,
      color var(--transition-duration) ease;
  }

  &__header-text {
    color: inherit;
    font-size: var(--font-size-md, 1.8rem);
    font-weight: 800;
    pointer-events: none;
    word-break: break-word;
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: height var(--transition-duration) ease;
  }

  &__content-wrapper {
    font-size: var(--font-size-base, 1.6rem);
    padding: 4.5rem 5rem 3.4rem 5.3rem;
  }

  &__description {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 2rem;
    white-space: break-spaces;
  }

  &__anchor {
    align-items: center;
    color: rgb(var(--dark-blue));
    display: flex;
    font-size: inherit;
    font-weight: 600;
    gap: 1rem;
    text-decoration: none;
  }

  &__anchor-icon {
    font-size: 1.5em;
  }

  &--open {
    .accordion__header {
      background-color: #fff;
    }

    .accordion__header-icon {
      color: rgb(var(--red));
      transform: rotate(135deg) scale(1.2);
    }
  }
}
