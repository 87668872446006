.table {
  border-collapse: separate;
  border-spacing: 0.2rem;
  color: rgb(var(--gray-step-1000));
  font-size: var(--font-size-xs);

  &__mobile-only {
    display: none;
  }

  th,
  td {
    border-radius: 1rem;
    padding: 1.5rem 1rem;
  }

  th {
    background-color: rgba(var(--cadet-blue), 25.1%);
    border-radius: 1rem;
    font-weight: var(--font-semibold);
    white-space: nowrap;
  }

  td {
    background-color: rgb(var(--gray-step-250));
    border-radius: 1rem;
    font-weight: var(--font-regular);

    &.session-container {
      background-color: rgb(var(--gray));
      padding: 0;
    }

    &.extracurricular {
      background-color: var(--category-color-quaternary-opacity-25);
    }

    &.camps {
      background-color: var(--category-color-secondary-opacity-25);
    }

    &.plans {
      background-color: var(--category-color-primary-opacity-25);
    }

    &.birthday {
      background-color: var(--category-color-tertiary-opacity-25);
    }
  }

  tr.selected {
    td {
      background-color: #c4e4ff;
    }
  }

  &--responsive {
    @media only screen and (max-width: 760px) {
      &__mobile-only {
        display: block;
      }

      td,
      tr {
        display: block;
      }

      thead tr {
        left: -9999px;
        position: absolute;
        top: -9999px;
      }

      tr + tr {
        margin-top: 1.5rem;
      }

      td {
        border-radius: 0 !important;

        &:first-child {
          border-radius: 1rem 1rem 0 0 !important;
        }

        &:last-child {
          border-radius: 0 0 1rem 1rem !important;
        }

        &:not(:last-child) {
          border: none;
          border-bottom: 1px solid rgb(var(--gray-step-400));
          position: relative;
        }

        &::before {
          color: rgb(var(--cadet-blue));
          content: attr(data-label);
          display: block;
          font-weight: var(--font-bold);
          line-height: 1.5;
          width: 100%;
        }
      }
    }
  }
}
